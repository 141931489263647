.logo {
    @include abs-pos(15px, auto, auto, 20px);
    position: fixed;
    @include rem(width, 117px);
    @include rem(height, 31px);

    img {
        @include abs-pos(0, auto, auto, 0);
        width: 100%;
        height: 100%;
    }

    @include breakpoint(1200) {
        @include abs-pos(9px, auto, auto, 25px);
        @include rem(width, 171px);
        @include rem(height, 46px);
    }

    @include modifier(footer) {
        @include relative-reset;
        display: block;
        @include rem(width, 171px);
        @include rem(height, 46px);
        @include rem(margin, 0 0 15px);
        svg {
            fill: #BBB;
        }
    }

    @include modifier(login) {
        @include relative-reset;
        @include rem(width, 221px);
        @include rem(height, 45px);
        display: inline-block;
        @include rem(margin, 0 0 2px);

        svg {
            @include abs-pos(0, 0, 0, 0);
            width: 100%;
            height: 100%;
            fill: $primary_blue;
        }
    }
}

.logo-text {
    @include abs-pos(17px, auto, auto, 148px);
    color: #777;
    @include rem(font-size, 8px);
    @include rem(line-height, 10px);
    text-transform: uppercase;
    @include rem(padding, 0 0 0 9px);

    &::before {
        content: '';
        @include abs-pos(50%, auto, auto, 0);
        @include transform(translateY(-50%));
        background: #EEE;
        @include rem(width, 1px);
        @include rem(height, 15px);
    }

    @include breakpoint(1200) {
        @include abs-pos(20px, auto, auto, 220px);
        @include rem(font-size, 10px);
        @include rem(line-height, 11px);
    }
}
