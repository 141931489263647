.button-holder {
    display: none;
    text-align: center;
    @include rem(padding, 0 20px 20px);

    @include breakpoint(phablet) {
        @include rem(padding, 0 40px 40px);
    }

    @include modifier(shown) {
        display: block;
    }
}
