.notification-holder {
    position: relative;
    @include rem(padding, 0 0 30px);
    width: 100%;

    @include breakpoint(tablet) {
        @include rem(padding, 0 30px 40px);
    }
    @include breakpoint(1400) {
        @include rem(padding, 0 40px 45px);
    }

    &::before {
        content: '';
        pointer-events: none;
        -ms-pointer-events: none;
        opacity: 0;
        @include abs-pos(0, 0, 0, 0);
        z-index: 10;
        background: #FFF;
    }

    &::after {
        content: '';
        @include abs-pos(50%, auto, auto, 50%);
        opacity: 0;
        background: url('../images/svg/loading-dark.svg') no-repeat center center;
        background-size: contain;
        @include rem(width, 40px);
        @include rem(height, 40px);
        @include transform(translate(-50%, -50%));
        pointer-events: none;
        -ms-pointer-events: none;
        z-index: 10;
    }

    @include modifier(loading) {
        @include rem(min-height, 50px);

        &::before {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 0.7;
        }
        &::after {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 1;
        }
    }
}
