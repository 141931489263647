.notification {
    @include rem(padding, 20px);
    position: relative;
    border-bottom: 2px solid #EEE;

    @include breakpoint(tablet) {
        @include rem(padding, 27px 0 29px);
    }

    @include element(title-meta) {
        display: block;
        text-align: left;
    }

    @include element(title) {
        @include rem(padding-right, 20px);
        @include breakpoint(tablet) {
            @include rem(padding-right, 50px);
        }
        @include breakpoint(1200) {
            @include rem(padding-right, 70px);
        }

        h2 {
            small {
                @include rem(font-size, 12px);
                @include rem(line-height, 16px);
                display: block;
                color: #00b2a1;

                @include breakpoint(laptop) {
                    @include rem(font-size, 14px);
                    @include rem(line-height, 18px);
                }
            }
        }
    }

    @include element(info) {
        display: none;
        @include rem(padding, 20px 0 0);

        p {
            color: #666;
            @include rem(font-size, 14px);
            @include rem(line-height, 20px);
            @include rem(padding, 0 0 17px);

            @include breakpoint(tablet) {
                @include rem(font-size, 16px);
                @include rem(line-height, 22px);
                @include rem(padding, 0 0 17px);
            }

            em {
                font-style: italic;
            }

            strong {
                font-weight: 500;
            }
        }

        ol {
            @include rem(margin, 0 0 0 30px);
            @include rem(padding, 0 0 20px);

            li {
                list-style: decimal;
                color: #666;
                @include rem(font-size, 14px);
                @include rem(line-height, 20px);
                @include rem(padding, 0 0 10px);

                @include breakpoint(tablet) {
                    @include rem(font-size, 16px);
                    @include rem(line-height, 22px);
                    @include rem(padding, 0 0 10px);
                }

                em {
                    font-style: italic;
                }

                strong {
                    font-weight: 500;
                }

                ol, ul {
                    @include rem(padding, 10px 0 0);

                    li:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }

        ul {
            @include rem(margin, 0);
            @include rem(padding, 0 0 20px);

            li {
                position: relative;
                color: #666;
                @include rem(font-size, 14px);
                @include rem(line-height, 20px);
                @include rem(padding, 0 0 10px 30px);

                @include breakpoint(tablet) {
                    @include rem(font-size, 16px);
                    @include rem(line-height, 22px);
                    @include rem(padding, 0 0 10px 30px);
                }

                &::before {
                    content: '';
                    @include abs-pos(8px, auto, auto, 9px);
                    @include rem(width, 6px);
                    @include rem(height, 9px);
                    background: url("../images/svg/navigation-arrow.svg") no-repeat center center;
                    background-size: cover;
                }

                em {
                    font-style: italic;
                }

                strong {
                    font-weight: 500;
                }

                ol, ul {
                    @include rem(padding, 10px 0 0);

                    li:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    @include element(time-period) {
        color: #777;
        @include rem(font-size, 14px);
        @include rem(line-height, 22px);
        @include rem(padding, 0 0 20px);
        display: block;

        @include breakpoint(tablet) {
            @include rem(font-size, 16px);
            @include rem(padding, 0 0 29px);
        }
    }

    @include element(meta-item) {

        text-decoration: none;
        color: #666;
        display: inline-block;
        @include rem(padding, 0 0 0 18px);
        @include rem(margin, 0 5px 5px 0);
        @include rem(font-size, 13px);
        @include rem(line-height, 13px);
        position: relative;

        @include breakpoint(tablet) {
            @include rem(font-size, 14px);
            @include rem(line-height, 14px);
            @include rem(margin, 0 20px 5px 0);
        }

        &::before {
            content: '';
            @include abs-pos(50%, auto, auto, 0);
            @include transform(translateY(-50%));
            @include rem(width, 12px);
            @include rem(height, 12px);
            padding: 0;
        }

        @include modifier(date) {
            @include rem(margin, 0 0 5px);
            width: 100%;
            &::before {
                background: url('../images/svg/notification-meta-date.svg') no-repeat center center;
                background-size: contain;
            }
            @include breakpoint(phablet) {
                width: auto;
                @include rem(margin, 0 20px 5px 0);
            }
        }

        @include modifier(author) {
            &::before {
                background: url('../images/svg/notification-meta-author.svg') no-repeat center center;
                background-size: contain;
            }
        }

        @include modifier(inactive) {
            &::before {
                left: 4px;
                @include rem(width, 8px);
                @include rem(height, 8px);
                @include border-radius(50%);
                background: #DDD;
            }
        }

        @include modifier(active) {
            &::before {
                left: 4px;
                @include rem(width, 8px);
                @include rem(height, 8px);
                @include border-radius(50%);
                background: #359E1D;
            }
        }

        @include modifier(dismissed) {
            &::before {
                left: 4px;
                @include rem(width, 8px);
                @include rem(height, 8px);
                @include border-radius(50%);
                background: #CB3D3D;
            }
        }

        span {
            display: none;
            @include breakpoint(tablet) {
                display: inline;
            }
        }
    }

    @include element(button) {
        border: none;
        @include abs-pos(50%, 20px, auto, auto);
        @include rem(width, 11px);
        @include rem(height, 20px);
        background: url('../images/svg/navigation-arrow-dark.svg') no-repeat center center;
        background-size: contain;
        @include transform(translateY(-50%));
        cursor: pointer;
        @include transition(0.3s linear);
        &:focus {
            outline: none;
        }
        @include breakpoint(laptop) {
            @include rem(right, 35px);
        }
    }

    @include modifier(open) {
        .notification {
            @include element(button) {
                @include rem(top, 40px);
                @include transform(translateY(0) rotate(-90deg));
            }
        }

        @include rem(padding, 20px 20px 10px);

        @include breakpoint(tablet) {
            @include rem(padding, 27px 0 19px);
        }
    }

    @include element("further-information") {
        display: inline-block;
        @include rem(margin, 20px 0 10px);
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        color: #555;
        @include rem(font-size, 13px);
        @include rem(line-height, 13px);
        @include rem(padding, 0 16px 6px 0);
        position: relative;

        &::before {
            content: '';
            display: none;
            @include abs-pos(auto, 16px, 0, 0);
            border-bottom: 2px dotted #00b2a1;
        }

        &:hover {
            &::before {
                display: block;
            }
        }

        &::after {
            content: '';
            @include abs-pos(1px, 2px, auto, auto);
            @include rem(width, 5px);
            @include rem(height, 10px);
            background: url('../images/svg/navigation-arrow-dark-alt.svg') no-repeat center center;
            background-size: contain;
        }
    }

    @include modifier(main) {

        h2 {
            @include rem(font-size, 18px);
            @include rem(line-height, 24px);

            @include breakpoint(tablet) {
                @include rem(font-size, 28px);
                @include rem(line-height, 36px);
            }

            @include breakpoint(laptop) {
                @include rem(font-size, 30px);
                @include rem(line-height, 38px);
            }

            &:hover {
                text-decoration: underline;
            }

        }

        .notification {

            @include element(button) {
                display: none;
            }

            @include element(info) {
                display: block;
            }

            @include element("further-information") {
                display: none;
            }
        }
    }

    @include element("details") {
        @include breakpoint("tablet") {
            @include rem(margin, 0 0 24px);
        }

        @include breakpoint("laptop") {
            @include rem(margin, 0 0 11px);
        }

        @include element("row") {
            border: 2px solid #EEE;
            @include rem(margin, 0 0 10px);

            @include breakpoint("tablet") {
                @include flex;
                @include vendor-simple(flex-flow, row wrap);
                @include vendor-simple(align-items, stretch);
                border: 0;
                margin: 0;
            }

            &:first-child {
                .notification__details__row__text {
                    @include breakpoint("tablet") {
                        border-top: 2px solid #EEE;
                    }

                    @include breakpoint("laptop") {
                        border-top: 2px solid #EEE;
                    }
                }
            }

            &:last-child {
                .notification__details__row__title {
                    border-bottom: 2px solid #EEE;
                }

                .notification__details__row__text {
                    @include breakpoint("tablet") {
                        @include rem(padding, 8px 16px 8px);
                    }

                    @include breakpoint("laptop") {
                        @include rem(padding, 8px 16px 8px);
                    }
                }
            }

            @include element("title") {
                background: #EEE;
                @include text($primary_font, 700, 12px, normal, $primary_grey);
                @include rem(line-height, 22px);
                @include rem(padding, 6px 8px);
                @include vendor-simple(flex, 1 1 auto);
                @include vendor-simple(align-content, center);

                @include breakpoint("tablet") {
                    width: 50.14%;
                    float: left;
                    border: 2px solid $primary_white;
                    border-top: 2px solid #EEE;
                    border-left: 2px solid #EEE;
                    text-align: right;
                    @include rem(padding, 6px 16px 6px 10px);

                    font: {
                        @include rem(size, 14.2px);
                    }
                }

                @include breakpoint("laptop") {
                    @include rem(padding, 6px 16px 6px 10px);
                }
            }

            @include element("text") {
                @include text($primary_font, 400, 12px, normal, $primary_grey);
                @include rem(line-height, 22px);
                @include rem(padding, 6px 8px);
                @include vendor-simple(flex, 1 1 auto);
                @include vendor-simple(align-content, center);

                .tabular-notifications & {
                    background: #FFF;
                }

                @include breakpoint("tablet") {
                    width: 49.43%;
                    float: left;
                    border: 2px solid #EEE;
                    border-top: 0;
                    @include rem(padding, 6px 16px 6px);
                }

                @include breakpoint("laptop") {
                    @include rem(padding, 6px 16px 6px);

                    font: {
                        @include rem(size, 14.2px);
                    }
                }
            }
        }
    }

}

body.archives-body, body.news-body {
    .notification--main h2 {
        &:hover {
            text-decoration: none;
        }
    }
}
