$mq-responsive: true;
$mq-transitions: true;
$mq-svg-support: true;

@import '../../../shared/src/css/reset.scss';
@import '../../../shared/src/css/mixins.scss';
@import '../../../shared/src/css/variables.scss';
@import '../../../shared/src/css/columns.scss';
@import '../../../shared/src/css/fonts.scss';
@import '../../../shared/src/css/text.scss';

@import 'layout';
@import "D:/national-grid/gas-remit/shared/src/css/styles/btn.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/dropzone.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/event-id-error.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/file-info.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/filter-holder.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/filtered.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/form__message.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/form-element.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/form.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/formrow.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/header.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/ie10.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/input.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/logo.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/menu-btn.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/message.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/mobile-filter-section.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/nav-close.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/nav-toggle.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/notification.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/subscribe.scss";
@import "D:/national-grid/gas-remit/shared/src/css/styles/textarea.scss";
@import "styles/_subscription-box.scss";
@import "styles/404.scss";
@import "styles/body.scss";
@import "styles/button-holder.scss";
@import "styles/contact-details.scss";
@import "styles/datepicker.scss";
@import "styles/filter-holder--sidebar.scss";
@import "styles/footer.scss";
@import "styles/form.scss";
@import "styles/g-recaptcha.scss";
@import "styles/header-content.scss";
@import "styles/headline-notification.scss";
@import "styles/html.scss";
@import "styles/label.scss";
@import "styles/main-content.scss";
@import "styles/main-image.scss";
@import "styles/main.scss";
@import "styles/nav.scss";
@import "styles/navigation.scss";
@import "styles/notification-holder.scss";
@import "styles/notification.scss";
@import "styles/nouislider-overrides.scss";
@import "styles/nouislider.scss";
@import "styles/page-content.scss";
@import "styles/page-header__select.scss";
@import "styles/page-header.scss";
@import "styles/recaptcha.scss";
@import "styles/related-remits.scss";
@import "styles/sm.scss";
@import "styles/smi.scss";
@import "styles/tabular-notifications.scss";
@import "styles/test-env.scss";
@import "styles/text-btn.scss";
@import "styles/umm-selector.scss";
@import "styles/useful-information-items.scss";
@import "styles/view-tab.scss";
@import "styles/view-tabs.scss";


html[data-useragent*="rv:11"] {
    .main-content {
        overflow: hidden;

        > div {
            width: 100%;
            overflow: hidden;
        }
    }
}

html[data-useragent*='MSIE 10.0'] {
    body::before, .notification-holder::before, .notification-holder.menu-btn__icon::after {
        display: none;
    }

    .main-content {
        overflow: hidden;

        > div {
            width: 100%;
            overflow: hidden;
        }
    }


    main {
        -ms-flex-align: end;
        -ms-flex-pack: end;
        -ms-flex-line-pack: flex-end;
    }

    .header-content {
        -ms-flex-align: end;
        -ms-flex-pack: end;
        -ms-flex-line-pack: flex-end;
    }

    .sm {
        @include flex();
    }
}

html[data-useragent*='MSIE 9.0'] {
    body::before, .notification-holder::before, .notification-holder.menu-btn__icon::after {
        display: none;
    }

    .main-content {
        float: right;
    }

    .uii {
        overflow: hidden;
    }

    .uii__item {
        float: left;
    }

    .header-content__text-holder {
        bottom: 224px;
        position: fixed;
        right: 900px;
        left: 0;
        width: auto;
    }

    .subscription-box {
        bottom: 0;
        position: fixed;
        right: 900px;
        left: 0;
        width: auto;
    }

    .input--inline {
        float: left;
        width: calc(100% - 171px);
    }

    .btn--inline {
        float: right;
    }

    .form-row--inline {
        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .fh__button {
        float: left;
    }

    .page-section--contact {
        float: left;
        width: 52.6666666666%;
    }

    .contact-form {
        float: right;
    }

    .cd {
        overflow: hidden;
    }

    .cd__icon {
        float: left;
    }


    .cd__info {
        float: left;
        width: calc(100% - 60px);
    }

    .sm {
        overflow: hidden;
    }

    .sm__icon {
        float: left;
    }

    .sm__info {
        float: right;
        width: calc(100% - 70px);
    }
}
