body {

    .noUi-handle {
        position: relative;
        z-index: 1;
    }

    .noUi-horizontal {
        height: 10px;
        border: 0;

        @include breakpoint(tablet) {
            height: 4px;
        }
    }

    .noUi-horizontal .noUi-handle {
        width: 26px;
        height: 26px;
        top: -9px;

        @include breakpoint(tablet) {
            width: 10px;
            height: 10px;
            top: -3px;
        }
    }

    .noUi-target {
        background: #CCC;
        border-radius: 6px;
        @include rem(margin-top, 3px);
        box-shadow: none;
    }

    .noUi-connect {
        background: #00B2A1;
        border-radius: 4px;
        box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
        -webkit-transition: background 450ms;
        transition: background 450ms;
    }

    .noUi-handle {
        border-radius: 50%;
        border: 0;

        -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.41);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.41);
        right: auto;
        left: 100%;

        &:focus {
            outline: none;
        }

        &::before, &::after {
            display: none;
        }
    }

}
