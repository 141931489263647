.content-skip-button {
    display: block;
    background: #000;
    text-align: center;
    max-height: 0;
    color: #FFF;
    font-family: sans-serif;
    font-weight: 300;
    text-decoration: none;
    @include rem(line-height, 14px);
    @include rem(font-size, 14px);
    @include transition(all 0.2s ease);
    overflow: hidden;
    position: relative;
    z-index: 500;
    margin: 0;
    line-height: initial;

    &:focus {
        @include rem(max-height, 200px);
        @include rem(padding, 10px);
    }
}

.notfound-body {
    max-width: 820px;
    margin: 0 auto;

    @include breakpoint("laptop") {
        @include rem(padding, 200px 0);
    }

    .notfound-content {
        @include rem(margin, 60px auto);
        @include rem(padding, 0 10px);
        text-align: center;

        h1 {
            text-align: center;
            @include rem(margin-bottom, 20px);
        }
    }
}

body {
    background: #DDD;
}
