.main-content {
    background: #FFF;
    overflow: hidden;
    pointer-events: all;
    -ms-pointer-events: all;

    @include breakpoint(1200) {
        @include rem(margin-top, 180px);
        @include vendor-simple(flex, 0 0 auto);
        @include flex();
        @include vendor-simple(flex-flow, row wrap);
        @include vendor-simple(justify-content, space-between);
        @include vendor-simple(align-items, stretch);
        @include transition(transform 0.3s linear);
        @include transform(translateX(280px));
    }

    @include breakpoint(1200) {
        @include rem(width, 1020px);
    }

    @include breakpoint(1400) {
        @include rem(width, 1140px);
    }

    @include breakpoint(1600) {
        @include rem(width, 1310px);
    }

    @include modifier(inner) {
        @include breakpoint(1200) {
            border-top: 4px solid #00b2a1;
            @include transform(translateX(0px));
        }

        @include breakpoint(1200) {
            @include rem(width, 740px);
        }

        @include breakpoint(1400) {
            @include rem(width, 860px);
        }

        @include breakpoint(1600) {
            @include rem(width, 1030px);
        }
    }
}

.page {
    width: 100%;

    @include element(contents) {
        @include breakpoint(tablet) {
            display: flex;
            position: relative;
            @include vendor-simple(flex-flow, row);
            @include vendor-simple(justify-content, flex-start);
            @include vendor-simple(align-items, stretch);
        }
    }

    @include element(content) {
        width: 100%;
        flex: 0 0 auto;
        @include breakpoint(tablet) {
            z-index: 1;
            position: relative;
        }

        @include breakpoint(1200) {
            @include breakpoint(1200) {
                width: calc(100% - 280px);
            }
        }
    }

    @include element(text) {
        @include rem(padding, 20px 20px 30px);

        @include breakpoint(tablet) {
            @include rem(padding, 25px 30px 40px);
        }

        @include breakpoint(1400) {
            @include rem(padding, 35px 40px 50px);
        }

        p {
            @include rem(padding, 0 0 43px);
        }
    }
}

.notification-filter-sidebar {
    @include rem(width, 280px);
    background: #EEE;

    @include breakpoint(tablet) {
        z-index: 2;
        @include abs-pos(0, 0, 0, auto);
        overflow: visible;
        @include transform(translateX(100%));
        @include transition(transform 0.3s linear);
    }

    @include breakpoint(1200) {
        @include relative-reset;
        @include vendor-simple(flex, 0 0 auto);
        transform: none;
    }
}

body.show-filters {

    @include breakpoint(tablet) {
        overflow: auto !important;
    }

    .notification-filter-sidebar {
        @include breakpoint(tablet) {
            @include transform(translateX(0));
        }
    }

    .main-content {
        @include breakpoint(1200) {
            @include rem(padding-right, 0px);
            @include transform(translateX(0px));
            @include rem(width, 1020px);
        }

        @include breakpoint(1400) {
            @include rem(width, 1140px);
        }

        @include breakpoint(1600) {
            @include rem(width, 1310px);
        }
    }

    .view-tab--filter {
        @include transform(translateX(0px));
    }

    .view-tab__hamburger {
        &::before {
            @include transform(rotate(40deg) scaleX(0.75));
        }

        &::after {
            @include transform(rotate(-40deg) scaleX(0.75));
        }
    }

    .view-tabs {
        padding: 0;
    }

    .header-content__text-holder,
    .subscription-box {
        @include breakpoint(1200) {
            @include transform(translateX(-280px));
        }
    }
}
