.sm {
    @include rem(max-width, 640px);
    margin: 0 auto;
    text-align: center;

    @include breakpoint(phablet) {
        display: flex;
        text-align: left;
    }

    @include modifier(contact) {
        text-align: center;
        display: block;
    }

    @include element(icon) {
        @include rem(height, 69px);
        @include rem(width, 69px);
        position: relative;
        display: inline-block;
        @include vendor-simple(flex, 0 0 auto);
        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #FFF;
        }
        @include modifier(contact) {
            @include rem(margin, 0 auto 20px);
        }

    }

    @include element(info) {
        @include breakpoint(phablet) {
            @include rem(padding, 0 20px);
        }

        h4 {
            color: #FFF;
            @include rem(font-size, 18px);
            @include rem(line-height, 20px);
            padding: 0;
            margin: 0;
            font-weight: 500;

            @include breakpoint(phablet) {
                @include rem(font-size, 20px);
                @include rem(line-height, 24px);
            }
        }

        p {
            color: #FFF;
            @include rem(font-size, 14px);
            @include rem(line-height, 22px);
            padding: 0;
            margin: 0;
            font-weight: 300;

            strong {
                color: #FFF;
            }

            @include breakpoint(phablet) {
                @include rem(font-size, 16px);
                @include rem(line-height, 24px);
            }
        }
    }
}
