.page-header {
    border-bottom: 2px solid #EEE;
    @include rem(font-size, 24px);
    @include rem(line-height, 32px);
    @include rem(padding, 0 0 14px);
    @include rem(margin, 0 0 14px);
    display: none;

    a {
        color: #009DDC;
        text-decoration: none;
    }

    @include breakpoint(tablet) {
        display: block;
        @include rem(font-size, 34px);
        @include rem(line-height, 36px);
        @include rem(padding, 0 0 20px);
        @include rem(margin, 0 0 20px);
    }

    @include breakpoint(laptop) {
        @include rem(font-size, 36px);
        @include rem(line-height, 40px);
    }

    @include breakpoint(1200) {
        @include rem(font-size, 40px);
        @include rem(line-height, 45px);
    }

    @include modifier(news) {
        margin: 0;
    }

    @include modifier(remits) {
        border: none;
        margin: 0;
        padding: 0;

        @include breakpoint(tablet) {
            @include rem(padding, 0 30px);
        }

        @include breakpoint(1400) {
            @include rem(padding, 0 40px);
        }

        h3 {
            display: block;
            color: #666;
            font-weight: 300;
            @include rem(font-size, 26px);
            @include rem(line-height, 28px);
            @include rem(padding, 25px 20px 16px);
            border-bottom: 2px solid #EEE;

            @include breakpoint(tablet) {
                @include rem(font-size, 36px);
                @include rem(line-height, 40px);
                @include rem(padding, 28px 0 19px);
            }

            @include breakpoint(1400) {
                @include rem(font-size, 40px);
                @include rem(line-height, 45px);
                @include rem(padding, 33px 0 23px);
            }
        }
    }
}
