.nav-toggle {
    z-index: 10;
    @include abs-pos(10px, 10px, auto, auto);
    position: fixed;
    @include rem(width, 40px);
    @include rem(height, 40px);
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    span {
        @include abs-pos(50%, auto, auto, 50%);
        @include rem(width, 20px);
        @include rem(height, 2px);
        background: #00b2a1;
        @include transform(translate(-50%, -50%));

        &::before, &::after {
            content: '';
            @include abs-pos(0, auto, auto, 0);
            @include rem(width, 20px);
            @include rem(height, 2px);
            background: #00b2a1;
            @include transform(translateY(-300%));
        }

        &::after {
            @include transform(translateY(300%));
        }
    }

    @include breakpoint(tablet) {
        display: none;
    }
}
