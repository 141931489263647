@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

@mixin abs-pos($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}

@mixin relative-reset {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    position: relative;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin box-shadow($left: 0, $top: 0, $blur: 0, $color: #000, $inset: "") {
    -webkit-box-shadow: $left $top $blur $color #{$inset};
    -moz-box-shadow: $left $top $blur $color #{$inset};
    box-shadow: $left $top $blur $color #{$inset};
}

@mixin text-shadow($left: 0, $top: 0, $blur: 0, $color: #000) {
    -webkit-text-shadow: $left $top $blur $color;
    -moz-text-shadow: $left $top $blur $color;
    text-shadow: $left $top $blur $color;
}

@function vendor-args($arg, $vendor) {
    $result: ();

    @each $i in $arg {
        @if $i == transform {
            $result: append($result, #{$vendor}#{$i}, space);
        } @else {
            $result: append($result, $i, space);
        }
    }
    @return $result;
}

@mixin vendor($property, $args) {
    @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
        $out: null;
        $i: 1;
        @each $count in $args {
            $values: null;

            @each $arg in nth($args, $i) {
                $values: append($values, vendor-args($arg, $vendor), space);
            }
            $out: append($out, $values, comma);
            $i: $i + 1;
        }

        #{$vendor}#{$property}: $out;
    }
}

@mixin transition($args...) {
    @if $mq-transitions == true {
        @include vendor(transition, $args);
    }
}

%vertical-align {
    @include abs-pos(50%, 0, auto, 0);
    @include transform(translateY(-50%));
}

@mixin aspect-breakpoint-min($point) {
    @if $mq-responsive == true {
        @media (min-aspect-ratio: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin aspect-breakpoint($point) {
    @if $mq-responsive == true {
        @media (aspect-ratio: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin aspect-breakpoint-max($point) {
    @if $mq-responsive == true {
        @media (max-aspect-ratio: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin rem($property, $values) {
    // Create a couple of empty lists as output buffers.
    $font-size: $base-font-size;
    $px-values: ();
    $rem-values: ();

    // Loop through the $values list
    @each $value in $values {
        // For each property value, if it's in rem or px, derive both rem and
        // px values for it and add those to the end of the appropriate buffer.
        // Ensure all pixel values are rounded to the nearest pixel.
        @if $value == 0 or $value == 0px {
            // 0 -- use it without a unit
            $px-values: join($px-values, 0);
            $rem-values: join($rem-values, 0);
        } @else if type-of($value) == number and not unitless($value) and unit($value) == px {
            // px value given - calculate rem value from font-size
            $new-rem-value: $value / $font-size;
            $px-values: join($px-values, round($value));
            $rem-values: join($rem-values, #{$new-rem-value}rem);
        } @else if type-of($value) == number and not unitless($value) and unit($value) == "%" {
            // % value given - don't add px or rem
            $px-values: join($px-values, #{$value});
            $rem-values: join($rem-values, #{$value});
        } @else if $value == auto {
            // auto - don't add px or rem
            $px-values: join($px-values, auto);
            $rem-values: join($rem-values, auto);
        } @else {
            // unitless value - use those directly as rem and calculate the px-fallback
            $px-values: join($px-values, round($value * $font-size));
            $rem-values: join($rem-values, #{$value}rem);
        }
    }
    // output the converted rules
    #{$property}: $px-values;
    #{$property}: $rem-values;
}

@mixin fontFace($family, $src, $weight: normal, $style: normal) {
    @font-face {
        font-family: $family;
        src: url("#{$src}.eot"); // IE9 compat
        src: url("#{$src}.eot?#iefix2") format("embedded-opentype"), url("#{$src}.woff") format("woff"), url("#{$src}.ttf") format("truetype"), url("#{$src}.svg##{$family}") format("svg"); // legacy iOS
        font-style: $style;
        font-weight: $weight;
    }
}

@mixin breakpoint($point) {
    @if $point == huge {
        @media (min-width: 87.5em) {
            @content;
        }
    } @else if $point == desktop {
        @if $mq-responsive == true {
            @media (min-width: 73.75em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == laptop {
        @if $mq-responsive == true {
            @media (min-width: 60em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == tablet {
        @if $mq-responsive == true {
            @media (min-width: 47.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == phablet {
        @if $mq-responsive == true {
            @media (min-width: 37.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == bigphone {
        @if $mq-responsive == true {
            @media (min-width: 30em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == mobileonly {
        @if $mq-responsive == true {
            @media (max-width: 30em) {
                @content;
            }
        }
    } @else {
        @if $mq-responsive == true {
            $value: $point / 16;

            @media (min-width: $value + em) {
                @content;
            }
        }
    }
}

@mixin custom-breakpoint($point) {
    @if $mq-responsive == true {
        @media (min-width: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin heightBreakpoint($point) {
    @if $point == huge {
        @media (min-height: 87.5em) {
            @content;
        }
    } @else if $point == desktop {
        @if $mq-responsive == true {
            @media (min-height: 73.75em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == laptop {
        @if $mq-responsive == true {
            @media (min-height: 60em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == tablet {
        @if $mq-responsive == true {
            @media (min-height: 47.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == phablet {
        @if $mq-responsive == true {
            @media (min-height: 37.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == bigphone {
        @if $mq-responsive == true {
            @media (min-height: 30em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == mobileonly {
        @if $mq-responsive == true {
            @media (max-height: 30em) {
                @content;
            }
        }
    }
}

@mixin max-breakpoint($point) {
    @if $point == huge {
        @media screen and (max-width: 87.5em) {
            @content;
        }
    } @else if $point == desktop {
        @if $mq-responsive == true {
            @media screen and (max-width: 73.75em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == laptop {
        @if $mq-responsive == true {
            @media screen and (max-width: 60em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == tablet {
        @if $mq-responsive == true {
            @media screen and (max-width: 47.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else if $point == phablet {
        @if $mq-responsive == true {
            @media screen and (max-width: 37.5em) {
                @content;
            }
        } @else {
            @content;
        }
    } @else {
        @if $mq-responsive == true {

            $value: $point / 16;

            @media screen and (max-width: $value + em) {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin custom-heightBreakpoint($point) {
    @if $mq-responsive == true {
        @media (min-height: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin custom-maxheightBreakpoint($point) {
    @if $mq-responsive == true {
        @media (max-height: $point) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin linear-gradient($fromColor, $toColor) {
    background-color: $fromColor; /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
    background-image: -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
    background-image: -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
    background-image: linear-gradient(top, $fromColor, $toColor);
}

@mixin horizontal-linear-gradient($fromColor, $toColor) {
    background-color: $fromColor; /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, right bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(to, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(to, $fromColor, $toColor); /* FF3.6 */
    background-image: -ms-linear-gradient(to, $fromColor, $toColor); /* IE10 */
    background-image: -o-linear-gradient(to, $fromColor, $toColor); /* Opera 11.10+ */
    background-image: linear-gradient(to, $fromColor, $toColor);
}

@mixin case-block($topic, $number) {
    .#{$topic}-#{$number} {
        background: url(../images/jpeg/case-studies/#{$topic}/#{$number}.jpg) no-repeat center;
        background-size: cover;
    }
}

@mixin svg-background($file, $repeat, $positionX, $positionY, $size, $color: "") {
    @if $mq-svg-support == true {
        $type: "svg";
        background: #{$color} url("../images/#{$type}/#{$file}.#{$type}") #{$repeat} #{$positionX} #{$positionY};
        background-size: #{$size};
    } @else {
        $type: "png";
        background: #{$color} url("../images/#{$type}/#{$file}.#{$type}") #{$repeat} #{$positionX} #{$positionY};
        background-size: #{$size};
        -ms-behavior: url(/js/backgroundsize.min.htc);
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-ms-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($delay, $duration, $animation, $type, $amount) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-timing-function: $type;
    -webkit-animation-iteration-count: $amount;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-timing-function: $type;
    -moz-animation-iteration-count: $amount;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-timing-function: $type;
    -o-animation-iteration-count: $amount;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-timing-function: $type;
    animation-iteration-count: $amount;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}

@mixin vendor-simple($property, $args...) {
    @each $vendor in "-webkit-", "-moz-", "-ms-", "-o-", "" {
        #{$vendor}#{$property}: $args;
    }
}

@mixin flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flexbox;
    display: flex;
}

@mixin placeholder() {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

@mixin element($element) {
    &__#{$element} {
        @content;
    }
}

@mixin modifier($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin Dmodifier($modifier1, $modifier2) {
    &--#{$modifier1} {
        @content;
    }
}

@mixin text($family, $weight, $size, $style, $color) {
    font: {
        family: $family;
        weight: $weight;
        size: $size;
        style: $style;
    }
    color: $color;

    strong {
        font: {
            weight: 700;
        }
    }
}

@mixin responsive_background($file,$type,$repeat,$position,$size) {
    background: url('#{$file}-768.#{$type}') #{$repeat} #{$position};
    background-size: #{$size};
    @include breakpoint(phablet) {
        background: url('#{$file}-960.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(tablet) {
        background: url('#{$file}-1024.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(laptop) {
        background: url('#{$file}-1200.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(desktop) {
        background: url('#{$file}-1400.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(huge) {
        background: url('#{$file}-1600.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(1600) {
        background: url('#{$file}-1800.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(1800) {
        background: url('#{$file}-2000.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
    @include breakpoint(2000) {
        background: url('#{$file}-2400.#{$type}') #{$repeat} #{$position};
        background-size: #{$size};
    }
}


@mixin firefox {
    html[data-useragent*="Firefox/"] & {
        @content;
    }
}

@mixin edge {
    html[data-useragent*="Edge"] & {
        @content;
    }
}

@mixin ie11 {
    html[data-useragent*="rv:11"] & {
        @content;
    }
}

@mixin safari {
    html[data-useragent*="Safari/"]:not([data-useragent*="Chrome"]) & {
        @content;
    }
}

@mixin safari-ios {
    html[data-useragent*="Safari/"]:not([data-useragent*="Chrome"]):not([data-useragent*="Macintosh;"]) & {
        @content;
    }
}

@mixin safari-mac {
    html[data-useragent*="Safari/"][data-useragent*="Macintosh;"]:not([data-useragent*="Chrome"]) & {
        @content;
    }
}

@mixin safari-ipad {
    html[data-useragent*="Safari/"][data-useragent*="iPad;"]:not([data-useragent*="Chrome"]) & {
        @content;
    }
}

@mixin safari-iphone {
    html[data-useragent*="Safari/"][data-useragent*="iPhone;"]:not([data-useragent*="Chrome"]) & {
        @content;
    }
}
