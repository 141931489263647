.page-header__select {
    display: block;
    position: relative;
    @include rem(padding, 0 35px 0 15px);
    @include rem(margin, 20px 20px 0);
    @include rem(font-size, 14px);
    @include rem(line-height, 40px);
    color: #555;
    font-weight: 400;
    border: 1px solid #CCC;
    width: calc(100% - 40px);
    @include rem(height, 42px);
    @include border-radius(3px);
    @include rem(padding, 0 35px 0 15px);

    @include breakpoint(tablet) {
        @include abs-pos(45px, 30px, auto, auto);
        @include rem(width, 200px);
        margin: 0;
        @include transform(translateY(-50%));
    }

    @include breakpoint(1400) {
        @include rem(top, 52px);
        @include rem(right, 40px);
    }

    select {
        @include abs-pos(0, auto, auto, 0);
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }

    .page-header__select-arrow {
        pointer-events: none;
        -ms-pointer-events: none;
        @include abs-pos(50%, 20px, auto, auto);
        @include transform(translateY(-50%));
        @include rem(width, 11px);
        @include rem(height, 6px);

        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #555;
        }
    }

}
