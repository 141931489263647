.container {
    width: 100%;
    margin: 0 auto;
    @include rem(max-width, 1300px);
    position: relative;
}

.demo-container {
    max-width: 605px;

    @include breakpoint("laptop") {
        max-width: 809px;
    }
}

.large-container {
    max-width: 1932px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.content {
    padding: 10px;
}

.column {
    display: block;
}

@include breakpoint("laptop") {
    .column {
        float: left;
        margin-left: 2.5%;

        &.right {
            float: right;
        }
    }

    .column:first-child {
        margin-left: 0;
    }

    .column.full {
        width: 100%;
    }

    .column.two-thirds {
        width: 65.3333333333%;

        &.fullcol {
            width: 67.8%;
            margin: 0;
        }
    }

    .column.half {
        width: 48.75%;

        &.fullcol {
            width: 50%;
            margin: 0;
        }
    }

    .column.one-third {
        width: 31.6666666666666%;

        &.fullcol {
            width: 32.2%;
            margin: 0;
        }
    }

    .column.one-fourth {
        width: 23.125%;

        &.fullcol {
            width: 25%;
            margin: 0;
        }
    }

    .column.three-fourths {
        width: 73.75%;

        &.fullcol {
            width: 75%;
            margin: 0;
        }
    }

    .column.one-fifth {
        width: 18%;

        &.fullcol {
            width: 20%;
            margin: 0;
        }
    }

    .column.two-fifths {
        width: 38.5%;

        &.fullcol {
            width: 40%;
            margin: 0;
        }
    }

    .column.three-fifths {
        width: 59%;

        &.fullcol {
            width: 60%;
            margin: 0;
        }
    }

    .column.four-fifths {
        width: 79.5%;

        &.fullcol {
            width: 80%;
            margin: 0;
        }
    }
}