.notification {
    @include element("details") {
        @include element(title) {
            display: none;
            @include abs-pos(0, auto, auto, 0);
            position: fixed;
            z-index: 100;
            width: 100%;
            @include rem(height, 50px);
            background: #009DDC;
            @include rem(padding, 14px 12px 0);

            h3 {
                color: #FFF;
                font-weight: 400;
                @include rem(font-size, 20px);
                @include rem(line-height, 20px);
            }

            @include max-breakpoint(759) {
                display: block;
            }
        }
    }
    @include element(close) {
        cursor: pointer;
        background: none;
        @include abs-pos(5px, 5px, auto, auto);
        position: fixed;
        @include rem(width, 40px);
        @include rem(height, 40px);
        -webkit-appearance: none;
        border: none;

        &:focus {
            outline: none;
        }

        &::before, &::after {
            content: '';
            @include abs-pos(50%, auto, auto, 50%);
            background: #FFF;
            @include transform(translate(-50%, -50%) rotate(-45deg));
            @include rem(width, 18px);
            @include rem(height, 2px);
        }

        &::after {
            @include transform(translate(-50%, -50%) rotate(45deg));
        }
    }

    @include element(details-row) {
        overflow: hidden;
    }

    @include element(details) {

        @include breakpoint(tablet) {
            @include rem(margin, 0 0 10px);
        }

        @include modifier(half) {
            @include breakpoint(laptop) {
                width: 49.05660377358491%;
                float: left;

                &:nth-child(even) {
                    float: right;
                }
            }
        }
    }

    @include element(details__row) {
        overflow: hidden;

        .tabular-notifications-wrap & {
            @include max-breakpoint(759) {
                margin: 0;
                position: relative;
                border-bottom: none;

                @include modifier(first) {
                    background: #EEE;
                    @include rem(padding, 7px 15px);
                    text-align: center;

                    .notification__details__row__title {
                        @include relative-reset;
                    }

                    .notification__details__row__text {
                        text-transform: uppercase;
                    }

                    .notification__details__row__title, .notification__details__row__text {
                        padding: 0;
                        display: block;
                        color: #555;
                        font-weight: 500;
                        text-align: center;
                        background: none;
                    }
                }
            }
        }
    }

    @include element(details__row__title) {
        font-family: "helvetica-neue", 'Arial', 'sans-serif';

        .tabular-notifications-wrap & {
            border: 2px solid #F8F8F8;
            border-top: 1px solid #F8F8F8;
            border-bottom: 1px solid #F8F8F8;
        }

        .tabular-notifications-wrap & {

            @include max-breakpoint(759) {
                border: none;
                text-align: left;
                background: none;
                font-weight: 500;
                padding: 0;
                color: #555;
                border: none !important;
                @include rem(font-size, 12px);
                @include rem(line-height, 18px);
                @include abs-pos(7px, auto, auto, 15px);

                @include breakpoint(phablet) {
                    @include abs-pos(10px, auto, auto, 20px);
                }
            }

            @include breakpoint(tablet) {
                @include rem(width, 200px);
                @include vendor-simple(flex, 0 0 auto);
            }

            @include breakpoint(laptop) {
                @include rem(width, 160px);
                @include flex();
                @include vendor-simple(justify-content, flex-end);
                @include vendor-simple(align-items, center);
                @include rem(font-size, 12px);
                @include rem(line-height, 20px);
                @include rem(padding, 6px 13px 6px 9px);
            }

            @include breakpoint(1600) {
                @include rem(width, 200px);
                @include rem(font-size, 14px);
                @include rem(line-height, 22px);
                @include rem(padding, 6px 16px 6px 10px);
            }
        }
    }

    @include element(details__row__text) {
        font-family: "helvetica-neue", 'Arial', 'sans-serif';
        font-weight: 400;

        .tabular-notifications-wrap & {
            @include max-breakpoint(759) {
                @include rem(font-size, 12px);
                @include rem(line-height, 18px);
                @include rem(padding, 7px 15px 7px 148px);

                @include modifier(large) {
                    @include rem(padding, 28px 15px 7px);
                }

                @include modifier(table) {
                    @include rem(padding, 32px 15px 0);

                    .display-table {
                        display: block;
                        width: 100%;

                        @include rem(margin, 0 0 7px);

                        thead {
                            display: none;
                        }

                        tbody {
                            tr {
                                display: block;
                                @include rem(margin, 0 0 7px);
                                border: 2px solid #EEE;

                                td {
                                    border-top: 2px solid #EEE;
                                    display: block;
                                    @include rem(padding, 7px 15px 7px 80px);
                                    position: relative;

                                    background: #FEFEFE;

                                    &:nth-child(even) {
                                        background: #F5F5F5;
                                    }

                                    @include breakpoint(phablet) {
                                        @include rem(padding, 10px 20px 10px 140px);
                                    }

                                    &:nth-child(1) {
                                        border: none;
                                    }

                                    &::before {
                                        content: attr(data-th);
                                        display: block;
                                        font-weight: 500;
                                        @include abs-pos(7px, auto, auto, 15px);

                                        @include breakpoint(phablet) {
                                            @include abs-pos(10px, auto, auto, 20px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint(laptop) {
            @include rem(font-size, 12px);
            @include rem(line-height, 20px);
        }

        @include breakpoint(1600) {
            @include rem(font-size, 14px);
            @include rem(line-height, 22px);
        }

        @include modifier(large) {
            @include breakpoint(laptop) {
                @include rem(min-height, 78px);
            }
        }

        @include modifier(table) {

            @include breakpoint(tablet) {
                padding: 0 !important;
            }

            @include breakpoint(laptop) {
                .display-table {
                    thead {
                        tr {
                            background: none !important;

                            th {
                                color: #444;
                                font-weight: 600;
                                border-left: 1px solid #EEE;
                                @include rem(padding, 12px 30px);
                                @include rem(font-size, 14px);
                                text-transform: none;

                                &:nth-child(1) {
                                    border-left: none;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border-left: 1px solid #EEE;
                                border-top: 1px solid #EEE;

                                color: #444;
                                @include rem(padding, 12px 30px);
                                @include rem(font-size, 14px);

                                &:nth-child(1) {
                                    border-left: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .umm__notification & {

        .notification {

            @include element(info) {
                display: block;
            }

            @include element(details) {
                margin: 0;
            }
        }
    }
}
