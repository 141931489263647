@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=bc3b5565-5d18-4fc8-98a9-99aa1eaa1f04");

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/c0c7b087-4dbd-4c75-8acc-8f4444b2ee1d.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/e206f16e-f3ae-45eb-b7a9-c0a42ed7537f.woff2") format("woff2"), url("../fonts/helvetica-neue/f4d1bafa-befd-4f3f-ba25-7c0f77c6651e.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i1";
    src: url("../fonts/helvetica-neue/9ca42d54-48d0-4428-892a-f254df0434df.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/91faf02a-e602-411f-8235-45b42c914e41.woff2") format("woff2"), url("../fonts/helvetica-neue/a5919350-0aef-4bd2-9809-88c50c764770.woff") format("woff");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n2";
    src: url("../fonts/helvetica-neue/d63f77e2-a23d-496f-bc30-c61c7a689aa6.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2") format("woff2"), url("../fonts/helvetica-neue/ca8d25cd-e264-4404-b271-4afc45c779c4.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i2";
    src: url("../fonts/helvetica-neue/55740f7d-3bf3-44a5-bfa0-2f1ef436ce72.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/a9445981-191b-46c7-866c-acb67f9cc1db.woff2") format("woff2"), url("../fonts/helvetica-neue/a0e9ae86-436e-483a-8201-6c694e90e9ee.woff") format("woff");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n3";
    src: url("../fonts/helvetica-neue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"), url("../fonts/helvetica-neue/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i3";
    src: url("../fonts/helvetica-neue/e36efb7d-12e3-4773-a830-26e5652373ea.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/261b4efb-3d70-4965-977d-38af9422700d.woff2") format("woff2"), url("../fonts/helvetica-neue/2e00514e-1764-4250-a0c5-aca3e5a8d133.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n4";
    src: url("../fonts/helvetica-neue/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"), url("../fonts/helvetica-neue/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i4";
    src: url("../fonts/helvetica-neue/5d0e5dca-869d-4e14-ac6b-45fbab3c4047.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/21c44514-f4d6-4cff-a5de-e4cac5e61aff.woff2") format("woff2"), url("../fonts/helvetica-neue/e7c4b231-76ad-47c7-a54b-5d84dcd78d0f.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n5";
    src: url("../fonts/helvetica-neue/f95f9fc2-ffda-431c-9d6a-2c3668f5b20b.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2"), url("../fonts/helvetica-neue/050b1948-f226-4d20-a65a-15d8ed031222.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i5";
    src: url("../fonts/helvetica-neue/4c68f39a-1d08-4a26-81b3-c424cbe6d712.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/657c2fa4-585b-4761-9e2e-65ab13159323.woff2") format("woff2"), url("../fonts/helvetica-neue/400869f0-f0d5-49a0-8a11-f4fb4279d125.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n7";
    src: url("../fonts/helvetica-neue/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"), url("../fonts/helvetica-neue/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i7";
    src: url("../fonts/helvetica-neue/52b6ae3e-9443-4acc-9bf9-f5154eeab77c.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/ade4dbae-c607-4c84-a375-f0c4de0cc357.woff2") format("woff2"), url("../fonts/helvetica-neue/5b864741-6f14-4ed9-a297-27a4d73edf51.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n8";
    src: url("../fonts/helvetica-neue/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/dd7814bd-8abf-46c5-a4be-db0977de2cca.woff2") format("woff2"), url("../fonts/helvetica-neue/20ffa4b4-0154-4ca6-800c-468cecbd60f1.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i8";
    src: url("../fonts/helvetica-neue/264fe832-63b5-439f-bacc-51c65507c211.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/a1ce96fc-e8fa-4118-8a5c-e06d8d094e56.woff2") format("woff2"), url("../fonts/helvetica-neue/c577fdb4-0e4a-4767-aa14-5510cf2c8fa4.woff") format("woff");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "helvetica-neue_n9";
    src: url("../fonts/helvetica-neue/6492b5a6-2267-40e3-8ede-fd8b730790b1.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/bd8486cc-4614-412d-ba1d-15e1a7c9fada.woff2") format("woff2"), url("../fonts/helvetica-neue/a7bdf5a9-752e-4e69-b17c-b7e62575b07a.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "helvetica-neue_i9";
    src: url("../fonts/helvetica-neue/8076926d-997f-43d6-9f72-fb7127f14fb0.eot?#iefix") format("eot")
}

@font-face {
    font-family: "helvetica-neue";
    src: url("../fonts/helvetica-neue/42535969-7bc6-4047-b3ce-bd8aea29420c.woff2") format("woff2"), url("../fonts/helvetica-neue/35e69fef-20b6-4357-b3a2-075224efa08a.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

.helvetica-neue {
    font-family: "helvetica-neue", 'Arial', 'sans-serif';
}
