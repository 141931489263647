body.test-env {
    header {
        border-top-color: #58A166;
    }

    h2 a {
        color: #58A166;
    }

    .navigation__item-holder::after {
        background: #58A166;
    }

    .navigation__item:hover, .navigation__item.btn:focus {
        color: #58A166;
    }

    .header-content__text-holder::after, .subscription-box::after {
        background: rgba(88, 161, 102, 0.8);
    }

    .btn--blue {
        background: #58A166;

        &:hover {
            background: darken(#58A166, 20%);
        }
    }

    .navigation {
        border-color: #58A166;
    }

    h2, h3 {
        color: #58A166;
    }

    .main-content--inner {
        border-color: #58A166;
    }

    .uii__item {
        .btn {
            &:hover {
                color: #358644;
            }
        }

        @include breakpoint(laptop) {
            &:hover {
                background: #358644;

                .btn {
                    background: #58A166;

                    &:hover {
                        background: #FFF;
                    }
                }
            }
        }
    }

    .contact-form {
        background: #58A166;
    }

    .cd__icon {
        background: #58A166;
    }

    .cd__info {
        h4 {
            color: #58A166;
        }
    }

    .fh__button:hover, .fh__button.btn:focus {
        background: darken(#58A166, 10%);

        .fh__button-text {
            color: #EEE;
        }

        .fh__button-small-text {
            color: #DDD;
        }
    }

    .fh__ab--apply {
        background-color: #58A166;
    }

    nav {
        background-color: #58A166;
        @include breakpoint(tablet) {
            background: none;
        }
    }


    .navigation__item {
        border-bottom-color: darken(#58A166, 10%);
    }

    $pages: "index", "news", "about", "useful-information", "contact", "";

    @each $page in $pages {
        &.#{$page}-body {
            .navigation__item-holder--#{$page} {
                .navigation__item {
                    background: #FFF;
                    font-weight: 700;
                    color: #333;

                    &::before {
                        background: url('../images/svg/navigation-arrow-dark.svg') no-repeat center center;
                        background-size: contain;
                    }

                    @include breakpoint(1200) {
                        color: #58A166;
                    }
                }

                @include breakpoint(1200) {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .notification__title h2 small {
        color: #358644;
    }

    .view-tabs {
        background: #58A166;
    }

    .view-tab--filter {
        background: #377B44;
    }

    .fh__radio:checked + .fh__radio-icon + .fh__radio-label {
        color: #358644;
        border-color: #358644;
    }

    .fh__clear-all {
        color: #358644;
    }

    .noUi-connect {
        background: #358644;
    }

    .view-tab--active .view-tab__icon svg {
        fill: #358644;
    }

    .tn__more-info::before, .tn__more-info.menu-btn__icon::after, .tn__more-info::after {
        background: #358644;
    }

    .fh__date-button--active {
        background: #58A166;

        &:hover {
            background: darken(#58A166, 20%);
        }
    }

    .fh__date-save-buttons .fh__ab--apply {
        background: #58A166;
        border-color: #58A166;

        &:hover {
            background: darken(#58A166, 20%);
            border-color: darken(#58A166, 20%);
        }
    }

    .datepicker td span.active:active, .datepicker td span.active.active {
        background: #58A166;
    }

    .datepicker td.active:active, .datepicker td.active:hover:active, .datepicker td.active.btn:active:focus, .datepicker td.active.active, .datepicker td.active:hover.active, .datepicker td.active.active.btn:focus {
        background: #58A166;
    }

    .logo {
        svg {
            fill: #58A166;
        }
    }

    .view-tab:hover {
        background: lighten(#58A166, 10%);
    }

    .view-tab--active:hover {
        background: #FFF;
    }

    .view-tab--filter:hover {
        background: darken(#377B44, 15%);
    }

    .fh__clear-all--disabled {
        color: #9E9E9E;
    }
}
