.input {
    @extend .helvetica-neue;
    -webkit-appearance: none;
    outline: none;
    @include border-radius(5px);
    width: 100%;
    @include rem(padding, 0 15px);
    //text-transform: uppercase;
    font-weight: 400;
    color: #333;
    @include transition(background 0.3s linear);
    @include rem(font-size, 16px);
    @include rem(line-height, 50px);

    &:focus {
        outline: none;
        background: #FFFED4;
    }

    @include rem(height, 40px);

    @include breakpoint(phablet) {
        @include rem(height, 50px);
    }

    @include placeholder() {
        font-family: "helvetica-neue", 'Arial', 'sans-serif';
        color: #999;
        text-transform: uppercase;
        font-weight: 600;
        @include rem(font-size, 14px);
        @include rem(line-height, 50px);

        @include firefox {
            @include rem(line-height, 36px);

            @include breakpoint(phablet) {
                @include rem(line-height, 44px);
            }

            @include breakpoint(1600) {
                @include rem(line-height, 54px);
            }
        }
    }

    @include breakpoint(1600) {
        @include rem(height, 60px);
    }

    @include modifier(inline) {
        @include border-radius(5px 0 0 5px);
        @include vendor-simple(flex, 1 1 auto);
    }

    @include modifier(filter) {
        background: none;
        border: none;
        @include border-radius(0);
        border-bottom: 4px;
        font-weight: 700;
        display: block;
        width: 100%;
        @include rem(padding, 0 0 0 25px);
        @include rem(height, 60px);
        color: #555;
        vertical-align: middle;
        cursor: pointer;
        @include rem(font-size, 14px);
        @include rem(line-height, 60px);
        border-bottom: 1px solid #DDD;
    }

    @include modifier(contact) {
        @include rem(height, 50px);
        @include rem(line-height, 50px);
        font-weight: 400;
        text-transform: none;
    }

    @include modifier(login) {
        @include rem(height, 50px);
        border: 1px solid #CCC;
        @include rem(padding, 0 15px 0 50px);
        text-transform: none;
        font-weight: 400;
        color: #555;
        @include rem(font-size, 16px);

        &.form__element--error {
            border: 1px solid #C92222;
        }
    }

    @include modifier(sidebar) {
        display: inline-block;
        border: none;
        width: auto;
        @include rem(height, 50px);
        color: #999;
        @include rem(font-size, 14px);
        @include rem(line-height, 50px);
        @include rem(padding, 0 40px 0 15px);
        position: relative;
        font-weight: 500;
        text-transform: none;

        &::before {
            content: '';
            @include abs-pos(50%, 21px, auto, auto);
            @include rem(width, 11px);
            @include rem(height, 6px);
            background: url('../images/svg/navigation-arrow-dropdown.svg') no-repeat center center;
            background-size: contain;
            @include transform(translateY(-50%));
            @include placeholder() {
                text-transform: none;
                font-weight: 400;
            }
        }
    }

    @include modifier(refine-sidebar) {
        border: none;
        -webkit-border-radius: 0;
        border-radius: 0;
        background-clip: padding-box;
        font-weight: 700;
        display: block;
        width: 100%;
        @include rem(padding, 0 0 0 25px);
        @include rem(height, 60px);
        color: #555;
        vertical-align: middle;
        cursor: pointer;
        @include rem(font-size, 14px);
        @include rem(line-height, 60px);
        border-bottom: 1px solid #DDD;

        @include breakpoint(tablet) {
            @include rem(height, 38px);
            border: 1px solid #CCC;
            @include rem(font-size, 13px);
            @include rem(line-height, 38px);
            @include rem(padding, 0 10px);
            @include border-radius(3px);
        }

        @include placeholder() {
            font-family: "helvetica-neue", 'Arial', 'sans-serif';
            color: #999;
            text-transform: none;
            font-weight: 300;
            font-style: italic;
            @include rem(font-size, 13px);
            @include rem(line-height, 38px);

            @include firefox {
                @include rem(line-height, 60px);
                @include breakpoint(tablet) {
                    @include rem(line-height, 38px);
                }
            }
        }
    }

    @include modifier(sidebar-text) {
        width: 100%;
        text-transform: none;
        @include placeholder() {
            text-transform: none;
            font-weight: 400;
        }
    }
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="month"]::-webkit-input-placeholder, input[type="date"]::placeholder, input[type="date"]::-moz-placeholder, input[type="date"]:-ms-input-placeholder {
    visibility: hidden !important;
}

input[type="month"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="month"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
