.datepicker {
    @include rem(padding, 4px);
    @include rem(margin-top, 1px);
    z-index: 4000;
    background: #FFF;
    display: block;
    width: 100%;
    font-weight: 400;
}

.js-datepicker-input {
    @include breakpoint(laptop) {
        display: none !important;
    }
}

.datepicker > div {
    //display: none;
}

.datepicker table {
    width: 100%;
    margin: 0;
}

.datepicker td,
.datepicker th {
    text-align: center;
    @include rem(width, 40px);
    @include rem(height, 32px);
    color: #555;
    @include rem(font-size, 14px);
    vertical-align: middle;
}

.datepicker td.day:hover {
    background: #EEE;
    cursor: pointer;
}

.datepicker td.day.disabled,
.datepicker td span.month.disabled,
.datepicker td span.year.disabled {
    color: #DCDCDC;
    background: #F7F7F7;
}

.datepicker td.old,
.datepicker td.new {
    color: #999;
}

.datepicker td.active,
.datepicker td.active:hover {
    background: #00b2a1;
    color: #FFF;
}

.datepicker td.active:hover,
.datepicker td.active:hover:hover,
.datepicker td.active:focus,
.datepicker td.active:hover:focus,
.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active,
.datepicker td.active.disabled,
.datepicker td.active:hover.disabled,
.datepicker td.active[disabled],
.datepicker td.active:hover[disabled] {
    color: #FFF;
    background-color: #00b2a1;
    *background-color: #00b2a1;
}

.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active {
    background-color: #00b2a1;
}

.datepicker td span {
    display: block;
    @include rem(width, 64px);
    @include rem(height, 50px);
    @include rem(line-height, 50px);
    float: left;
    margin: 2px;
    cursor: pointer;
}

.datepicker td span:hover {
    background: #EEE;
}

.datepicker td span.active {
    background: #00b2a1;
    color: #FFF;
}

.datepicker td span.active:hover,
.datepicker td span.active:focus,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
    color: #FFF;
    background-color: #00b2a1;
}

.datepicker td span.active:active,
.datepicker td span.active.active {
    background-color: #00b2a1;
}

.datepicker td span.old {
    color: #CCC;
}

.datepicker th.switch {
    width: 145px;
}

.datepicker th.next,
.datepicker th.prev {
    font-size: 21px;
}

.datepicker thead tr:first-child th {
    cursor: pointer;
}

.datepicker thead tr:first-child th:hover {
    background: #EEE;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
}
