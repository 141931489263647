.textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    @extend .helvetica-neue;
    -webkit-appearance: none;
    outline: none;
    @include border-radius(5px);
    @include rem(padding, 15px);
    font-weight: 400;
    color: #333;

    @include rem(height, 140px);

    &:focus {
        outline: none;
    }

}
