.navigation {
    border-top: 1px solid #00B2A1;
    margin: 0;
    display: block;
    width: 100%;
    @include breakpoint(tablet) {
        @include rem(padding-right, 20px);
    }

    @include breakpoint(laptop) {
        @include rem(padding-right, 40px);
    }

    @include element(item-holder) {
        display: block;
        width: 100%;
        list-style: none;
        margin: 0;
        position: relative;
        @include breakpoint(tablet) {
            float: left;
            width: auto;
            @include rem(margin-left, 10px);
        }
        @include breakpoint(1200) {
            @include rem(margin-left, 30px);
        }
        @include breakpoint(1400) {
            @include rem(margin-left, 50px);
        }

        &::after {
            content: '';
            width: 0;
            @include abs-pos(auto, auto, 0, 0);
            @include rem(height, 1px);
            background: #00857a;
        }
    }

    @include element(item) {
        font-weight: 300;
        display: block;
        border-bottom: 1px solid #00857A;
        color: #FFF;
        text-decoration: none;
        margin: 0;
        @include rem(font-size, 16px);
        @include rem(line-height, 16px);
        @include rem(padding, 22px 30px 20px 40px);
        position: relative;

        &::before {
            content: '';
            @include abs-pos(50%, auto, auto, 20px);
            @include rem(width, 6px);
            @include rem(height, 11px);
            background: url('../images/svg/navigation-arrow.svg') no-repeat center center;
            background-size: contain;
            @include transform(translateY(-50%));
        }

        @include breakpoint(tablet) {
            @include rem(font-size, 11px);
            @include rem(line-height, 11px);
            padding: 0;
            text-decoration: none;
            border: none;
            color: #555;
            font-weight: 700;
            text-transform: uppercase;
            @include rem(padding, 24px 10px 23px);
            &::before {
                display: none;
            }
        }

        @include breakpoint(laptop) {
            @include transition(color 0.3s linear);
            &:hover {
                color: #00857A;
            }
        }

        @include breakpoint(1200) {
            @include rem(font-size, 12px);
            @include rem(line-height, 12px);
            @include rem(padding, 28px 10px 23px);
        }
    }
}

$pages: "index", "news", "about", "useful-information", "contact";

@each $page in $pages {
    body.#{$page}-body {
        .navigation__item-holder--#{$page} {
            .navigation__item {
                background: #FFF;
                font-weight: 700;
                color: #333;

                &::before {
                    background: url('../images/svg/navigation-arrow-dark.svg') no-repeat center center;
                    background-size: contain;
                }

                @include breakpoint(1200) {
                    color: #00857A;
                }
            }

            @include breakpoint(1200) {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}
