.event-id-error {
    display: inline-block;
    @include rem(padding, 0 15px);

    p {
        @include rem(font-size, 13px);
        @include rem(line-height, 15px);
        color: #C92222;

        a {
            color: #C92222;
            border-color: #C92222;
        }
    }
}

.inline-loader {
    display: none;
    @include rem(width, 30px);
    @include rem(height, 30px);
    @include rem(margin, 0 20px);
    vertical-align: middle;
    background: url('../images/svg/loading-dark.svg') no-repeat center center;
    background-size: contain;

    @include modifier(loading) {
        display: inline-block;
    }
}
