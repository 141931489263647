.cd {
    display: block;
    @include flex;
    @include rem(margin, 0 0 30px);

    @include element(icon) {
        @include rem(width, 60px);
        @include rem(height, 60px);
        background: #00b2a1;
        position: relative;
        @include border-radius(50%);
        display: block;
        @include vendor-simple(flex, 0 0 auto);

        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #FFF;
        }
    }

    @include element(info) {
        @include rem(padding, 0 0 0 20px);
        @include flex;
        @include vendor-simple(flex-flow, column wrap);
        @include vendor-simple(justify-content, center);
        h4 {
            @include rem(width, 100%);
            color: #00b2a1;
            @include rem(font-size, 10px);
            @include rem(line-height, 10px);
            letter-spacing: 0.15rem;
            text-transform: uppercase;
            padding: 0;
            margin: 0;

            @include breakpoint(tablet) {
                @include rem(font-size, 11px);
                @include rem(line-height, 11px);
            }
        }
        p {
            @include rem(width, 100%);
            font-weight: 300;
            @include rem(font-size, 18px);
            @include rem(line-height, 28px);
            padding: 0;
            margin: 0;
            @include breakpoint(tablet) {
                @include rem(font-size, 22px);
                @include rem(line-height, 32px);
            }

            a {
                color: #555;
                text-decoration: none;
                border: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
