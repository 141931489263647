.umm__notification {
    position: relative;
    @include rem(padding, 58px 0 0);
    @include rem(margin, 0 8px 18px);
    border-bottom: 2px solid #EEE;

    @include breakpoint(tablet) {
        border-bottom: none;
        margin: 0;
        @include rem(padding, 8px 8px 0);
        border-top: 2px solid #EEE;
    }
}

.umm__notification {
    .display-table {
        width: 100%;
    }
}
