.subscribe {
    @include rem(padding, 36px 20px 33px);
    background: #00457E;

    @include breakpoint("tablet") {
        @include rem(padding, 77px 54px 93px);
    }

    @include modifier("notifications") {
        display: none;
        background: url(../images/jpeg/signup-form-background-laptop.jpg) center center no-repeat;
        background-size: cover;

        @include breakpoint("laptop") {
            display: block;
        }
    }

    .form {
        @include modifier("subscribe") {
            @include breakpoint("tablet") {
                @include flex;
                @include vendor-simple(flex-flow, row wrap);
                @include vendor-simple(align-items, flex-end);
            }

            @include breakpoint("laptop") {
                @include rem(max-width, 660px);
                margin: 0 auto;
            }
        }

        @include element("loading") {
            @include modifier("subscribe") {
                @include vendor-simple(flex, 1 0 auto);
                width: 100%;
                max-width: 100%;
                @include rem(margin, 0 0 40px);
            }
        }

        @include element("row") {
            @include modifier("subscribe") {
                @include breakpoint("tablet") {
                    @include vendor-simple(flex, 1 0 auto);
                    width: 38%;
                    max-width: 38%;
                    float: left;
                    margin: 0;
                    margin-right: 1.5%;
                }
            }

            @include modifier("submit") {
                @include breakpoint("tablet") {
                    @include vendor-simple(flex, 1 0 auto);
                    margin: 0;
                    margin-right: 0;
                    width: 21%;
                    max-width: 21%;
                    float: left;
                }
            }
        }

        @include element("label") {
            @include modifier("subscribe") {
                color: $primary_white;
            }
        }

        @include element("element") {
            @include modifier("subscribe") {
                border: 0;
                @include rem(padding, 15px 6px);

                @include breakpoint("laptop") {
                    @include rem(padding, 0 10px);
                    @include rem(height, 48px);
                }
            }

            @include modifier("error") {
                border: 3px solid #A43232;

                @include breakpoint("laptop") {
                    @include rem(padding, 0 10px);
                }
            }
        }
    }
}

.form--subscribe {
    .form__message {
        @include breakpoint("laptop") {
            @include abs-pos(-6%, -91px, auto, auto);
            width: 90%;
        }
    }

    .form__message--success {
        @include abs-pos(64%, 16px, auto, auto);
        width: 16px;
    }
}
