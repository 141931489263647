footer {
    background: #F3F3F3;
    @include rem(padding, 30px 20px);

    @include breakpoint(phablet) {
        @include rem(padding, 40px 30px 45px);
    }

    @include breakpoint(laptop) {
        @include rem(padding, 65px 30px 60px);
    }

    @include breakpoint(1200) {
        @include rem(padding, 40px 0 45px 30px);
    }

    @include breakpoint(1400) {
        @include rem(padding, 55px 0 60px 40px);
    }

    @include breakpoint(1600) {
        @include rem(padding, 60px 100px 65px 40px);
    }
}

body.index-body {
    footer {
        @include breakpoint(1200) {
            @include rem(padding-right, 280px);
        }

        @include breakpoint(1400) {
            @include rem(padding-right, 280px);
        }

        @include breakpoint(1600) {
            @include rem(padding-right, 380px);
        }
    }
}

.footer {
    @include rem(width, 100%);
    overflow: hidden;
    @include element(links) {
        display: block;
        overflow: hidden;
        @include breakpoint(phablet) {
            float: right;
            width: 50%;
        }
    }

    @include element(links-half) {
        float: left;
        width: 50%;
    }

    @include element(links-holder) {
        @include rem(margin, 0 0 30px);
        @include breakpoint(phablet) {
            margin: 0;
        }
    }

    @include element(link-holder) {
        display: block;
        @include rem(padding, 0 0 8px);
    }

    @include element(link) {
        text-decoration: none;
        color: #999;
        font-weight: 300;
        @include rem(font-size, 13px);
        @include rem(line-height, 20px);
        padding: 0;
        @include breakpoint(laptop) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include element(info) {
        @include breakpoint(phablet) {
            float: left;
            width: 50%;
        }
        p {
            font-weight: 300;
            color: #AAA;
            @include rem(font-size, 11px);
            @include rem(line-height, 18px);
            padding: 0;

            a {
                text-decoration: none;
                color: #AAA;
                border: none;
            }

            @include breakpoint(tablet) {
                @include rem(font-size, 12px);
                @include rem(line-height, 20px);
            }
        }
    }
}
