.recaptcha {
    width: 196px;
    height: 44px;
    display: flex;
    flex-flow: row;
    margin: 22px auto 0 0;
    overflow: hidden;
    flex: 0 0 auto;
    border-radius: 10px;
    border: 1px solid #D2D2D2;

    .recaptcha__text {
        padding: 6px 8px 6px 12px;
        text-align: left;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        span, a {
            color: #999;
        }

        span {
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
        }

        a {
            font-size: 10px;
            text-decoration: none;
            position: relative;
            margin-right: 12px;

            &:hover {
                text-decoration: underline;
            }

            &:nth-of-type(1) {
                &::after {
                    content: "";
                    position: absolute;
                    inset: 50% -8px auto auto;
                    transform: translateY(-50%) translateY(1px);
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    background: #999;
                    border-radius: 50%;
                }
            }
        }
    }

    .recaptcha__logo {
        background: #E9E9E9;
        width: 44px;
        height: 44px;
        display: inline-block;
        position: relative;
        flex: 0 0 auto;
        border-radius: 0 5px 5px 0;

        svg {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
}
