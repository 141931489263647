.form__message {
    display: none;
    @include abs-pos(100%, auto, auto, 0);
    background: #C92222;
    color: #FFF;
    @include rem(font-size, 12px);
    @include rem(line-height, 12px);
    @include rem(padding, 8px);
    @include rem(margin-top, -3px);
    @include border-radius(0 0 5px 5px);

    @include modifier(invalid) {
        display: block;
    }

    @include modifier(login) {
        z-index: -1;
        @include rem(margin-top, -5px);
        @include rem(padding, 13px 8px 8px);
    }
}
