body {
    height: 100%;
    font-family: 'helvetica-neue', 'Arial', 'Sans-Serif';
    position: relative;
    padding-top: 58px !important;
    border-top: 2px solid #00b2a1;

    @include breakpoint(1200) {
        padding-top: 63px !important;
    }

    &::before {
        content: '';
        @include abs-pos(0, 0, 0, 0);
        position: fixed;
        z-index: 600;
        pointer-events: none;
        -ms-pointer-events: none;
        background: #000;
        opacity: 0;
        @include transition(opacity 0.3s linear);
    }

    &.show-navigation {
        overflow: hidden !important;
        right: 260px;

        &::before {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 0.9;
            cursor: pointer;
            @include breakpoint(tablet) {
                opacity: 0;
            }
        }

        @include breakpoint(tablet) {
            overflow: auto;
            @include transform(translateX(0px));
        }
    }

    &.show-notification, &.show-filters {
        overflow: hidden !important;

        &::before {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 0.9;
            cursor: pointer;
            @include breakpoint(tablet) {
                opacity: 0;
            }
        }

        @include breakpoint(tablet) {
            overflow: auto;
            &::before {
                pointer-events: none;
                -ms-pointer-events: none;
                opacity: 0;
            }
        }
    }

    &.show-notification, &.show-filters {
        overflow: hidden !important;

        &::before {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 0.9;
            cursor: pointer;
            @include breakpoint(tablet) {
                opacity: 0;
            }
        }

        @include breakpoint(tablet) {
            overflow: auto !important;
            &::before {
                pointer-events: none;
                -ms-pointer-events: none;
                opacity: 0;
            }
        }
    }
}
