.filtered {
    display: none;
    position: relative;

    @include breakpoint(tablet) {
        background: #EEE;
        @include rem(padding, 15px 15px 0 130px);
        text-align: left;
        @include modifier(shown) {
            display: block;
        }

        @include modifier("shown") {
            display: block;
        }
    }

    @include element(title) {
        display: none;
        @include breakpoint(tablet) {
            display: block;
            color: #666;
            font-weight: 500;
            text-transform: uppercase;
            @include rem(font-size, 12px);
            @include rem(line-height, 12px);
            @include abs-pos(29px, auto, auto, 30px);
        }
    }

    @include element(item) {
        background: #E3E0E0;
        display: inline-block;
        @include rem(margin, 0 15px 15px 0);
        @include border-radius(300px);
        @include rem(padding, 8px 40px 11px 20px);
        position: relative;
        span {
            color: #666;
            @include rem(font-size, 13px);
            @include rem(line-height, 13px);
        }
    }

    @include element(remove) {
        @include abs-pos(50%, 20px, auto, auto);
        @include rem(width, 12px);
        @include rem(height, 12px);
        border: none;
        background: none;
        cursor: pointer;
        @include transform(translateY(-50%));
        outline: none;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        &::before, &::after {
            content: '';
            @include abs-pos(50%, auto, auto, 50%);
            @include rem(width, 14px);
            @include rem(height, 2px);
            background: #999;
            @include transform(translate(-50%, -50%) rotate(-45deg));
        }

        &::after {
            @include transform(translate(-50%, -50%) rotate(45deg));
        }
    }
}
