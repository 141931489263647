.main-image {
    @include abs-pos(0, 0, 0, 0);
    z-index: -1;
    @include responsive_background('../images/jpeg/main-header-image', 'jpg', 'no-repeat', 'top left', 'cover');
    @include breakpoint(1200) {
        @include abs-pos(0, 0, 0, 0);
        position: fixed;
        z-index: -1;
    }

    $pages: 'news', 'about', 'useful-information', 'contact';

    @each $page in $pages {
        body.#{$page}-body & {
            @include responsive_background('../images/jpeg/#{$page}-header-image', 'jpg', 'no-repeat', 'top left', 'cover');
        }
    }

}


