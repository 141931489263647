.smi {
    display: block;
    text-align: left;
    @include rem(margin, 0 0 10px);
    @include element(icon) {
        position: relative;
        @include rem(margin, 0 10px 10px 0);
        display: inline-block;
        @include border-radius(50%);
        background: #CCC;
        @include rem(width, 40px);
        @include rem(height, 40px);
        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #FFF;
        }
        @include breakpoint(laptop) {
            @include transition(background 0.3s linear);
            &:hover {
                background: #007BC1;
            }

            @include modifier(twitter) {
                &:hover {
                    background: #00ACED;
                }
            }

            @include modifier(facebook) {
                &:hover {
                    background: #3B5998;
                }
            }

            @include modifier(rss) {
                &:hover {
                    background: #EE802F;
                }
            }
        }
    }
}
