nav {
    @include abs-pos(0, auto, 0, 100%);
    height: 100%;
    position: fixed;
    z-index: 700;
    background: #00b2a1;
    @include rem(width, 260px);
    @include rem(padding-top, 60px);

    @include breakpoint(tablet) {
        @include abs-pos(1px, 0, auto, auto);
        height: auto;
        position: fixed;
        background: none;
        padding: 0;
        width: auto;
    }


    body.show-navigation & {
        left: calc(100% - 260px);
        @include breakpoint(tablet) {
            left: auto;
        }
    }
}
