.menu-btn {
    display: inline-block;
    @include rem(width, 40px);
    @include rem(height, 40px);
    @include abs-pos(50%, 10px, auto, auto);
    @include transform(translateY(-50%));
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    @include transition(all 0.4s linear);

    @include breakpoint("tablet") {
        @include rem(width, 77px);
    }

    @include breakpoint("laptop") {
        display: none;
    }

    &:focus {
        outline: 1px dashed $primary_blue;
    }

    @include element("text") {
        display: none;
        @include text($primary_font, 700, 13px, normal, $primary_blue);

        @include breakpoint("tablet") {
            display: block;
            @include abs-pos(50%, auto, auto, 4px);
            @include transform(translateY(-50%));
        }
    }

    @include element("icon") {
        display: inline-block;
        @include rem(width, 20px);
        @include rem(height, 2px);
        background: $primary_blue;
        @include abs-pos(50%, auto, auto, 50%);
        @include transform(translate(-50%, -50%) rotate(0deg));
        @include transition(background 0.2s ease 0.2s);

        @include breakpoint("tablet") {
            @include abs-pos(50%, 4px, auto, auto);
            @include transform(translateY(-50%) rotate(0deg));
        }

        &::before {
            content: "";
            display: inline-block;
            @include rem(width, 20px);
            @include rem(height, 2px);
            background: $primary_blue;
            @include abs-pos(50%, auto, auto, 50%);
            @include transform(translate(-50%, -358%) rotate(0deg));
            @include transition(transform 0.4s linear);
        }

        &::after {
            @extend ::before;
            @include transform(translate(-50%, 252%) rotate(0deg));
        }
    }
}