.subscription-box {
    position: relative;

    &::after {
        content: '';
        @include abs-pos(0, 0, 0, 0);
        z-index: -1;
        background: rgba(0, 178, 161, 0.8);
    }

    width: 100%;
    @include rem(padding, 30px 20px);
    z-index: 100;

    @include breakpoint(phablet) {
        @include rem(padding, 40px 20px 45px);
    }

    @include breakpoint(laptop) {
        @include rem(padding, 40px 20px 40px);
    }

    @include breakpoint(1200) {
        @include transition(transform 0.3s linear);
        @include rem(padding, 45px 40px);
    }

    @include breakpoint(1600) {
        @include rem(padding, 40px 70px 57px);
    }

    p {
        color: #FFFEFE;
        @include rem(padding, 0 0 4px);
        @include rem(max-width, 500px);
        margin: 0 auto;
        @include breakpoint(tablet) {
            font-weight: 500;
        }
        @include breakpoint(1200) {
            @include rem(max-width, 510px);
        }
        @include breakpoint(1600) {
            @include rem(font-size, 20px);
            @include rem(padding, 0 0 9px);
        }
    }
}
