.uii {
    @include rem(padding, 10px 0 0);

    @include breakpoint(phablet) {
        @include flex;
        @include vendor-simple(flex-flow, row wrap);
        @include vendor-simple(align-items, stretch);
    }

    @include breakpoint(tablet) {
        @include rem(padding, 20px 0 0);
    }
    @include breakpoint(1200) {
        @include rem(padding, 30px 0 0);
    }

    @include element(item) {
        background: #EEE;
        @include rem(margin, 0 0 20px);
        @include rem(padding, 30px 20px 70px);
        position: relative;

        @include breakpoint(phablet) {
            @include vendor-simple(flex, 1 1 auto);
            width: 40%;
            @include rem(margin, 0 0 20px 20px);
            &:nth-child(odd) {
                @include rem(margin, 0 0 20px);
            }
        }

        @include breakpoint(laptop) {
            width: 30%;
            @include rem(padding, 30px 20px 20px);
            @include rem(margin, 0 0 20px 20px);
            &:nth-child(odd) {
                @include rem(margin, 0 0 20px 20px);
            }
            &:nth-child(3n+1) {
                @include rem(margin, 0 0 20px);
            }
        }

        @include modifier(spacer) {
            display: none;
            background: none;
            opacity: 0;

            @include breakpoint(phablet) {
                display: block;
            }
        }

        h4 {
            font-weight: 300;
            @include rem(font-size, 20px);
            @include rem(line-height, 26px);
            color: #666;
            @include rem(padding, 0 0 15px);
            position: relative;
            @include rem(margin, 0 0 20px);

            &::after {
                content: '';
                @include abs-pos(auto, auto, 0, 0);
                @include rem(width, 40px);
                @include rem(height, 2px);
                background: #00b2a1;
            }

            @include breakpoint(tablet) {

            }

            @include breakpoint(laptop) {
                @include rem(padding, 0 0 50px);
            }
        }

        .btn {
            @include breakpoint(laptop) {
                @include transition(transform 0.3s linear, background 0.3s linear, color 0.3s linear);
            }
        }

        @include breakpoint(laptop) {
            &:hover {
                background: #00b2a1;

                h4 {
                    color: #FFF;

                    &::after {
                        background: #FFF;
                        @include rem(bottom, 42px);
                    }
                }

                .uii {
                    @include element(info) {
                        opacity: 0;
                    }
                    @include element(bg-icon) {
                        svg {
                            fill: #FFF;
                            opacity: 1;
                        }
                    }
                }

                .btn {
                    opacity: 1;
                    @include transform(none);

                    &:hover {
                        color: #00b2a1;
                        background: #FFF;
                    }
                }
            }
        }
    }

    @include element(info) {
        display: block;
        margin: 0;
        @include rem(padding, 0 0 15px);

        @include breakpoint(laptop) {
            padding: 0;
        }
    }

    @include element(info-text) {
        display: block;
        margin: 0;
        padding: 0;
        @include rem(font-size, 14px);
        @include rem(line-height, 20px);
        font-weight: 400;
        color: #777;
        strong {
            font-weight: 700;
            @include rem(margin-right, 20px);
            color: #555;
        }
    }

    @include element(bg-icon) {
        @include abs-pos(0, auto, auto, 0);
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        fill: #000;
        opacity: 0.05;

        svg {
            @include abs-pos(0, 0, auto, auto);
            width: 260px;
            height: 200px;
        }
    }
}
