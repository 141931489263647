header {
    background: #FFF;
    @include abs-pos(0, auto, auto, 0);
    position: fixed;
    width: 100%;
    @include rem(height, 60px);
    border-top: 2px solid #00b2a1;
    z-index: 500;
    @include vendor-simple(box-shadow, 0px 0px 5px 0px rgba(0, 0, 0, 0.4));

    @include breakpoint(1200) {
        @include rem(height, 65px);
    }
}

.header {
    @include modifier(admin) {
        @include vendor-simple(box-shadow, none);
    }
}
