.page-content {

    @include rem(padding, 20px);

    @include breakpoint(tablet) {
        @include rem(padding, 30px);
    }

    @include breakpoint(1200) {
        @include rem(padding, 40px);
    }

    @include modifier(news) {
        @include rem(padding, 20px 20px 0);

        @include breakpoint(tablet) {
            @include rem(padding, 30px 30px 0);
        }

        @include breakpoint(1200) {
            @include rem(padding, 40px 40px 0);
        }
    }

    @include modifier(contact) {
        padding: 0;

        @include breakpoint(tablet) {
            @include flex();
        }
    }

    ul {
        @include rem(padding, 0 0 20px);

        li {
            @extend p;
            position: relative;
            @include rem(padding, 0 0 14px 40px);
            @include breakpoint(laptop) {
                @include rem(padding, 0 0 16px 40px);
            }

            &::before {
                content: '';
                @include abs-pos(6px, auto, auto, 20px);
                @include rem(width, 6px);
                @include rem(height, 11px);
                background: url('../images/svg/navigation-arrow-dark.svg') no-repeat center center;
                background-size: contain;
            }
        }
    }

    ol {
        @include rem(padding, 0 0 20px);

        li {
            @extend p;
            position: relative;
            @include rem(padding, 0 0 14px 5px);
            @include rem(margin, 0 0 0 35px);
            @include breakpoint(laptop) {
                @include rem(padding, 0 0 16px 5px);
            }
            list-style: decimal-leading-zero;
        }
    }

}

.page-section {
    @include rem(padding, 20px);

    @include breakpoint(tablet) {
        @include rem(padding, 30px);
        width: 50%;
    }

    @include breakpoint(laptop) {
        width: 56.6666666666%;
    }

    @include breakpoint(1200) {
        @include rem(padding, 40px);
    }

    @include modifier(contact) {
        p {
            color: #666;
        }
    }
}

.contact-form {
    background: #00b2a1;
    @include rem(padding, 40px 20px);

    @include breakpoint(tablet) {
        @include rem(padding, 40px 30px);
        width: 50%;
        @include flex;
        @include vendor-simple(justify-content, center);
        @include vendor-simple(align-items, center);
    }

    @include breakpoint(laptop) {
        width: 44.44444444444444%;
    }

    @include breakpoint(1200) {
        @include rem(padding, 40px);
    }
}
