.dropzone {
    @include abs-pos(0, 0, 0, 0);
    pointer-events: none;
    display: none;
    z-index: 7000;
    @include rem(padding, 80px);
    background: #EFEFEF;

    &::before {
        content: '';
        @include abs-pos(40px, 40px, 40px, 40px);
        border: 4px dashed #D7D7D7;
    }

    @include element(content) {
        @include abs-pos(80px, 80px, 80px, 80px);
        @include flex();
        @include vendor-simple(flex-flow, column wrap);
        @include vendor-simple(justify-content, center);
        @include vendor-simple(align-items, center);

        h2 {
            @extend .helvetica-neue;
            font-weight: 500;
            @include rem(font-size, 24px);
            @include rem(line-height, 24px);
            color: #727272;
        }
    }
}


body.show-dropzone {
    .dropzone {
        display: block;
    }
}
