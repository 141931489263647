.file-info {
    display: block;
    float: left;
    width: 100%;
    @include rem(padding, 5px 30px 10px 0);

    @include element(name) {
        @extend .helvetica-neue;
        display: block;
        @include rem(font-size, 18px);
        @include rem(line-height, 24px);
        @include rem(padding, 0 0 6px);
    }

    @include element(progress) {
        width: 100%;
        position: relative;
        background: #E3E3E3;
        @include rem(height, 14px);
    }

    @include element(bar) {
        @include abs-pos(0, auto, auto, 0);
        height: 100%;
        background: $primary_blue;
    }
}
