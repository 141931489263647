.view-tab {
    @extend .helvetica-neue;
    font-weight: 400;
    display: block;
    background: none;
    border: none;
    @include rem(font-size, 9px);
    @include rem(line-height, 9px);
    color: #FFF;
    position: relative;
    cursor: pointer;
    @include rem(padding, 46px 0 15px);

    @include rem(height, 70px);
    text-align: center;
    width: 33.3%;
    transition: background 0.3s linear;

    &:hover {
        background: darken(#00857A, 5%);
    }

    @include breakpoint(tablet) {
        width: auto;
        @include vendor-simple(flex, 0 0 auto);
        @include rem(font-size, 12px);
        @include rem(line-height, 70px);
        text-align: left;
        @include rem(padding, 0 45px 0 66px);
    }

    @include breakpoint(1400) {
        @include rem(padding, 0 45px 0 59px);
    }

    @include breakpoint(1600) {
        @include rem(padding, 0 55px 0 69px);
        @include rem(line-height, 80px);
        @include rem(height, 80px);
    }

    &:focus {
        outline: none;
    }

    @include modifier(active) {
        background: #FFF;
        color: #555;

        &:hover {
            background: #FFF;
        }

        .view-tab__icon svg {
            fill: #00857a;
        }
    }

    @include modifier(filter) {
        margin-left: auto;
        font-weight: 500;
        background: #00857A;
        padding: 0;
        display: none;
        transition: background 0.3s linear;

        &:hover {
            background: darken(#00857A, 15%);
        }

        @include breakpoint(tablet) {
            display: block;
            @include rem(padding, 0 53px 0 30px);
            text-align: left;
        }

        @include breakpoint(1200) {
            position: absolute;
            right: 0;
            @include transform(translateX(-280px));
            @include transition(transform 0.3s linear, background 0.3s linear);
        }

        @include breakpoint(1600) {
            width: auto;
            text-indent: 0;
            @include rem(padding, 0 62px 0 41px);
        }
    }

    @include element(icon) {
        @include rem(width, 23px);
        @include rem(height, 27px);
        @include abs-pos(14px, auto, auto, 50%);
        @include transform(translateX(-50%));

        @include breakpoint(tablet) {
            @include abs-pos(50%, auto, auto, 26px);
            @include transform(translateY(-50%));
        }

        @include breakpoint(1600) {
            @include rem(left, 37px);
        }

        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #FFF;
        }
    }

    @include element(hamburger) {
        @include rem(width, 16px);
        @include rem(height, 2px);
        background: #FFF;
        @include abs-pos(50%, auto, auto, 50%);
        @include transform(translate(-50%, -50%));

        &::before,
        &::after {
            content: '';
            @include abs-pos(0, 0, auto, auto);
            @include rem(width, 16px);
            @include rem(height, 2px);
            background: #FFF;
            @include vendor-simple(transform-origin, right center);
            @include transform(translateY(-250%));
            @include transition(transform 0.3s linear);
        }

        &::after {
            @include transform(translateY(250%));
        }

        @include breakpoint(tablet) {
            @include abs-pos(50%, 28px, auto, auto);
            @include transform(translateY(-50%));
        }

        @include breakpoint(1600) {
            @include rem(right, 38px);
        }
    }
}
