.nav-close {
    z-index: 10;
    @include abs-pos(10px, 10px, auto, auto);
    @include rem(width, 40px);
    @include rem(height, 40px);
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &::before, &::after {
        content: '';
        @include abs-pos(50%, auto, auto, 50%);
        @include rem(width, 18px);
        @include rem(height, 2px);
        background: #FFF;
        @include transform(translate(-50%, -50%) rotate(45deg));
    }

    &::after {
        @include transform(translate(-50%, -50%) rotate(-45deg));
    }

    @include breakpoint(tablet) {
        display: none;
    }

    @include modifier(admin-filter) {
        @include breakpoint(tablet) {
            display: block;
        }
        @include breakpoint(1200) {
            display: none;
        }
    }
}
