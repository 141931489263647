.form {
    width: 100%;

    @include element(header) {
        h4 {
            color: #FFF;
            @include rem(font-size, 22px);
            @include rem(line-height, 22px);
            @include rem(padding, 0 0 6px);
            font-weight: 500;
        }

        p {
            color: #FFF;
            @include rem(font-size, 14px);
            @include rem(line-height, 20px);
            @include rem(padding, 0 0 20px);
        }
    }
}
