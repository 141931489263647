.view-tabs {
    display: block;
    background: #00b2a1;
    @include rem(height, 70px);
    overflow: hidden;
    @include flex();

    @include breakpoint(1200) {
        @include rem(padding-right, 280px);
        @include transition(padding 0.3s linear);
    }

    @include breakpoint(1600) {
        @include rem(height, 80px);
    }
}
