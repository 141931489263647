.mobile-filter-section {
    overflow: hidden;
    background: #EEE;
    @include breakpoint(tablet) {
        display: none;
    }

    h3 {
        float: left;
        @include rem(padding, 22px 0 0 20px);
        color: #555;
        font-weight: 400;
        @include rem(font-size, 20px);
        @include rem(line-height, 20px);
        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include modifier(admin) {
        @include rem(margin, -15px -15px 15px);

        @include breakpoint(tablet) {
            display: block;
            background: none;
            @include abs-pos(15px, 35px, auto, auto);
        }

        @include breakpoint(laptop) {
            .btn--filter {
                display: block;
            }
        }

        @include breakpoint(1200) {
            display: none;
        }
    }
}
