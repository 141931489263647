body {
    @extend .helvetica-neue !optional;
}

h1 {
    @include rem(font-size, 22px);
    @include rem(line-height, 28px);
    font-weight: 300;
    color: #FFF;
    padding: 0;
    margin: 0;
    text-align: center;
    @include breakpoint(phablet) {
        @include rem(font-size, 32px);
        @include rem(line-height, 42px);
        text-align: left;
    }
    @include breakpoint(1600) {
        @include rem(font-size, 36px);
        @include rem(line-height, 52px);
    }
}

h2 {
    color: #00b2a1;
    font-weight: 400;
    margin: 0;
    @include rem(font-size, 16px);
    @include rem(line-height, 22px);
    @include rem(padding, 0 0 6px);

    a {
        color: #00b2a1;
        text-decoration: none;
    }

    @include breakpoint(tablet) {
        @include rem(padding, 0);
        @include rem(font-size, 22px);
        @include rem(line-height, 28px);
    }

    @include breakpoint(laptop) {
        @include rem(font-size, 26px);
        @include rem(line-height, 32px);
    }

    @include breakpoint(1200) {
        @include rem(font-size, 24px);
        @include rem(line-height, 30px);
    }
}

h3 {
    color: #00b2a1;
    font-weight: 400;
    margin: 0;
    @include rem(font-size, 24px);
    @include rem(line-height, 30px);
    @include rem(padding, 0 0 6px);

    a {
        color: #00b2a1;
        text-decoration: none;
    }

    @include breakpoint(tablet) {
        @include rem(padding, 0);
        @include rem(font-size, 26px);
        @include rem(line-height, 32px);
    }

    @include breakpoint(laptop) {
        @include rem(font-size, 28px);
        @include rem(line-height, 42px);
    }

    @include breakpoint(1200) {
        @include rem(font-size, 30px);
        @include rem(line-height, 45px);
    }
}

h4 {
    color: #555;
    font-weight: 700;
    @include rem(font-size, 14px);
    @include rem(line-height, 20px);
    padding: 0;
    margin: 0;
    @include breakpoint(tablet) {
        @include rem(font-size, 16px);
        @include rem(line-height, 22px);
    }
}

h5 {
    @include rem(font-size, 14px);
    @include rem(line-height, 24px);
    font-weight: 500;
    color: #999;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    @include rem(padding, 0 0 6px);
}

p {
    color: #666;
    font-weight: 400;
    @include rem(font-size, 14px);
    @include rem(line-height, 20px);
    @include rem(padding, 0 0 14px);
    @include breakpoint(laptop) {
        @include rem(font-size, 16px);
        @include rem(line-height, 22px);
        @include rem(padding, 0 0 16px);
    }

    strong {
        font-weight: 700;
        color: #555;
    }

    a {
        text-decoration: none;
        color: #00b2a1;
        border-bottom: 1px solid #00b2a1;

        strong {
            color: #00b2a1;
        }
    }
}

