@include breakpoint(tablet) {
    .fh {
        @include modifier(sidebar) {
            background: none;

            @include breakpoint(tablet) {
                z-index: 200;
                @include rem(padding, 0 0 30px);
            }

            @include breakpoint(1200) {
                padding: 0;
            }

            .fh {
                @include element(view-container) {
                    display: block;
                    @include relative-reset;
                    box-shadow: none;
                    background: none;
                    transform: none;
                    overflow: hidden;
                    border-radius: 0;
                    width: auto;
                    @include rem(margin, 0 0 30px);
                    @include rem(padding, 0 25px);

                    &::before, &::after {
                        display: none;
                    }

                    @include modifier(main) {
                        display: none;
                    }

                    @include modifier(overflow) {
                        overflow: visible;
                    }

                    @include modifier(mobile-only) {
                        @include breakpoint(tablet) {
                            display: none;
                        }
                    }
                }

                @include element(link) {
                    font-family: "helvetica-neue", 'Arial', 'sans-serif';
                    padding: 0;
                    font-weight: 700;
                    border: none;
                    color: #555;
                    @include rem(font-size, 14px);
                    @include rem(line-height, 14px);
                    @include rem(padding, 0 0 8px);
                    text-align: left;
                }

                @include element(checkboxes) {
                    @include breakpoint(tablet) {
                        padding: 0;
                    }
                }

                @include element(checkbox-holder) {
                    @include breakpoint(tablet) {
                        width: 100%;
                        float: none;
                    }
                }

                @include element(checkbox-label) {
                    color: #555;
                    @include rem(margin, 0 0 9px);
                    @include rem(padding, 0 10px 0 20px);

                    &::before {
                        background: #FFF;
                        border-color: #CCC;
                    }
                }

            }
        }
    }
}
