main {
    pointer-events: none;
    -ms-pointer-events: none;
    @include breakpoint(1200) {
        position: relative;
        z-index: 10;
        width: 100%;
        min-height: 100%;
        @include flex();
        @include vendor-simple(flex-flow, row);
        @include vendor-simple(justify-content, flex-end);
        @include vendor-simple(align-items, stretch);
        overflow: hidden;
    }
}