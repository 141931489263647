.header-content {
    position: relative;
    @include rem(padding, 200px 0px 0px);

    @include breakpoint(phablet) {
        @include rem(padding, 270px 0px 0px);
    }

    @include breakpoint(laptop) {
        @include rem(padding, 280px 0px 0px);
    }

    @include breakpoint(1200) {
        @include abs-pos(0, 0, 0, 0);
        position: fixed;
        z-index: 10;
        @include flex();
        @include vendor-simple(flex-flow, column);
        @include vendor-simple(justify-content, flex-end);
        @include vendor-simple(align-items, flex-end);
        @include rem(padding-right, 740px);
    }

    @include breakpoint(1400) {
        @include rem(padding-right, 860px);
    }

    @include breakpoint(1600) {
        @include rem(padding-right, 1030px);
    }

    @include element(text-holder) {
        width: 100%;
        position: relative;

        @include breakpoint(1200) {
            @include transition(transform 0.3s linear);
        }

        @include breakpoint(1600) {
            @include rem(padding, 0 70px);
        }
    }

    @include element(text) {
        width: 100%;
        text-align: left;
        @include rem(padding, 20px 20px 18px);
        @include rem(max-width, 540px);
        margin: 0 auto;

        @include breakpoint(phablet) {
            @include rem(padding, 30px 30px 28px);
            @include rem(max-width, 560px);
        }

        @include breakpoint(laptop) {
            @include rem(padding, 50px 50px 29px);
            @include rem(max-width, 600px);
            margin: 0 auto;
        }

        @include breakpoint(1200) {
            @include rem(padding, 65px 40px 29px);
            @include rem(max-width, 580px);
        }

        @include breakpoint(1600) {
            @include rem(max-width, 510px);
            @include rem(padding, 65px 40px 29px 0);
            margin: 0 auto;
        }
    }
}