.fh {
    @include abs-pos(0, 0, 0, auto);
    position: fixed;
    z-index: 3000;
    background: #F8F8F8;
    width: 90%;
    @include rem(max-width, 360px);

    @include transform(translate(100%));
    @include transition(transform 0.3s linear);

    @include breakpoint(tablet) {
        @include transition(none);
    }

    body.show-filters & {
        @include transform(translate(0%));
    }

    body.show-navigation & {
        @include transform(translate(200%));
    }

    @include breakpoint(tablet) {
        @include relative-reset;
        display: block;
        @include transform(translate(0));
        background: #00b2a1;
        width: 100%;
        max-width: none;
    }

    @include element(view-container) {
        background: #F8F8F8;
        @include abs-pos(0, 0, 77px, 0);
        overflow: auto;
        @include transform(translate(100%));

        @include modifier(shown) {
            @include transform(translate(0));
        }

        @include breakpoint(tablet) {
            display: none;
            @include modifier(shown) {
                display: block;
            }
            @include modifier(main) {
                background: none;
                @include relative-reset;
                display: block;
                transform: none;
                width: 100%;
                max-width: none;
                @include flex;
                @include vendor-simple(flex-flow, row);
            }
            @include modifier(drop-down) {
                @include abs-pos(65px, auto, auto, 20px);
                @include rem(width, 280px);
                background: #FFF;
                @include border-radius(5px);
                @include vendor-simple(box-shadow, 0px 0px 3px 0px rgba(0, 0, 0, 0.2));
                overflow: visible;

                &::before {
                    @include border-radius(3px 0 0 0);
                    content: '';
                    z-index: -1;
                    @include rem(width, 20px);
                    @include rem(height, 20px);
                    background: #FFF;
                    @include abs-pos(0, auto, auto, 50%);
                    @include transform(translate(-50%, -50%) rotate(45deg));
                }

                @include breakpoint(laptop) {
                    @include rem(top, 75px);
                }

            }

            @include modifier(publication-date) {
                @include rem(left, 20px);
                &::before {
                    @include rem(left, 60px);
                    @include breakpoint(desktop) {
                        @include rem(left, 100px);
                    }
                }
            }

            @include modifier(overflow) {
                overflow: auto;
            }
        }

        @include modifier(archive) {
            bottom: 0;
            @include breakpoint(tablet) {
                bottom: auto;
            }
        }
    }

    @include element(archive-link) {
        @include rem(font-size, 10px);
        @include rem(line-height, 10px);
        color: #666;

        a {
            color: #00b2a1;

            &:hover {
                color: darken(#00b2a1, 20%);
            }
        }
    }

    @include element(view-container-inner) {
        position: relative;
        width: 100%;
        min-height: 100%;
        //@include rem(padding-bottom, 77px);
        @include flex();
        @include vendor-simple(justify-content, space-between);
        @include vendor-simple(flex-flow, column);
        @include breakpoint(tablet) {
            padding: 0;
        }

        @include modifier(archive) {
            @include vendor-simple(justify-content, flex-start);
        }
    }

    @include element(link) {
        font-weight: 500;
        @include rem(font-size, 22px);
        font-style: normal;
        color: #555;
        @include rem(padding, 22px 25px);
        float: none;
        cursor: pointer;
        background: #EEE;
        text-decoration: none;
        display: block;
        width: 100%;
        position: relative;
        border: none;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        @include breakpoint(tablet) {
            display: none;
        }

        @include modifier(inner) {

            @include rem(padding, 22px 25px 22px 55px);

            &::before {
                content: '';
                background: none;
                outline: none;
                border: none;
                -webkit-appearance: none;
                background: url('../images/svg/mobile-view__btn.svg') center center no-repeat;
                background-size: contain;
                @include rem(width, 9px);
                @include rem(height, 17px);
                @include abs-pos(25px, auto, auto, 25px);
            }

            @include breakpoint(tablet) {
                display: block;
                color: #999;
                background: none;
                @include rem(padding, 20px 25px 18px 15px);
                border-bottom: 1px solid #EEE;
                @include rem(font-size, 11px);
                @include rem(line-height, 11px);
                font-weight: 500;
                text-transform: uppercase;

                &::before {
                    display: none;
                }
            }
        }

        @include modifier(small) {
            @include rem(font-size, 16px);
            @include rem(padding, 17px 20px 15px);
            text-transform: uppercase;
            border-bottom: 1px solid #DDD;
            border-top: 1px solid #DDD;
            background: #FAFAFA;

            @include breakpoint(tablet) {
                display: block;
                color: #999;
                background: none;
                @include rem(padding, 20px 25px 18px 15px);
                border-bottom: 1px solid #EEE;
                @include rem(font-size, 11px);
                @include rem(line-height, 11px);
                font-weight: 500;

                &::before {
                    display: none;
                }
            }
        }

        @include modifier(mobile-only) {
            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    @include element(button) {
        @extend .helvetica-neue;
        -webkit-appearance: none;
        border: none;
        outline: none;
        background: none;
        display: block;
        width: 100%;
        border-bottom: 1px solid #DDD;
        text-align: left;
        @include rem(padding, 21px 25px);
        cursor: pointer;
        &:focus {
            outline: none;
        }
        @include breakpoint(tablet) {
            border: none;
            color: #FFFEFE;
            padding: 0;
            width: 30%;
            text-align: center;
            @include modifier(active) {
                .fh {
                    @include element(button-text) {
                        border-top: 2px solid #FFF;
                    }
                }
            }
        }

        @include breakpoint(laptop) {
            @include transition(background 0.3s linear);
            &:hover {
                background: #008BC3;
            }
        }

        @include modifier(disabled) {
            cursor: not-allowed;
            opacity: 0.5;
            @include breakpoint(laptop) {
                &:hover {
                    background: transparent;
                }
            }
        }
    }

    @include element(auto-complete-container) {
        position: relative;
        overflow: visible;
    }

    @include element(auto-complete) {
        display: none;

        @include breakpoint(tablet) {
            @include abs-pos(calc(100% - 1px), auto, auto, 0);
            width: 100%;
            background: #FAFAFA;
            border: 1px solid #CCC;
            @include rem(max-height, 200px);
            overflow: auto;
            z-index: 100;
        }

        ul {
            li {
                display: block;
            }
        }

        @include modifier("show") {
            display: block;
        }

        .inline-loader {
            display: block;
            margin: 9px auto;
        }
    }

    @include element(auto-complete-list) {
        display: block;
        border-bottom: 1px solid #E0E0E0;

        @include breakpoint(tablet) {
            border: none;
        }
    }

    @include element(auto-complete-list-item) {
        display: block;
        border-top: 1px solid #E0E0E0;
        &:nth-child(1) {
            border: none;
        }
        &:nth-child(even) {
            background: #F6F6F6;
        }

        &:hover {
            background: #F0F0F0;

            &:nth-child(even) {
                background: #ECECEC;
            }
        }
    }

    @include element(auto-complete-button) {
        @extend .helvetica-neue;
        font-weight: 300;
        display: block;
        background: none;
        border: none;
        -webkit-appearance: none;
        @include rem(font-size, 14px);
        @include rem(padding, 13px 25px);
        width: 100%;
        text-align: left;
        color: #616161;
        cursor: pointer;

        @include breakpoint(tablet) {
            @include rem(font-size, 13px);
            @include rem(padding, 12px 15px);
        }

        &:focus {
            outline: none;
        }

        strong, small {
            display: block;
        }

        strong {
            font-weight: 700;
            @include rem(font-size, 14px);
        }
        small {
            @include rem(padding, 3px 0 0);
            font-style: italic;
            @include rem(font-size, 12px);
        }
    }

    @include element("auto-complete-message") {
        @include rem(font-size, 12px);
        @include rem(line-height, 16px);
        @include rem(padding, 13px 13px 12px);
        text-align: left;
        font-weight: 500;

        @include modifier("info") {
            background: #DDD;
            color: #3D3D3D;
        }

        @include modifier("error") {
            background: #B43431;
            color: #FFF;
        }
    }

    @include element(slider-wrap) {
        @include rem(padding, 28px 30px 10px);

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    @include element(title) {
        @include rem(padding, 25px 26px 22px);
        @include rem(margin, 0 0 24px);
        background: #E0E0E0;
        position: relative;
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }

        h3 {
            color: #555;
            @include rem(font-size, 22px);
            @include rem(line-height, 22px);
            padding: 0;
            margin: 0;
        }
    }

    @include element(clear-all) {
        @extend .helvetica-neue;
        background: none;
        border: none;
        -webkit-appearance: none;
        @include abs-pos(50%, 25px, auto, auto);
        transform: translateY(-50%);
        text-align: right;
        color: #00b2a1;
        @include rem(font-size, 12px);
        @include rem(line-height, 12px);
        z-index: 10;
        opacity: 1;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        @include modifier("disabled") {
            color: #9E9E9E;
        }
    }

    @include element(button-text) {
        display: block;
        font-weight: 700;
        color: #555;
        @include rem(font-size, 16px);
        @include rem(line-height, 16px);
        margin: 0;
        @include rem(padding, 0 0 4px);

        @include breakpoint(tablet) {
            display: inline-block;
            color: #FFFEFE;
            @include rem(font-size, 13px);
            @include rem(line-height, 70px);
            font-weight: 400;
            @include rem(padding, 0 20px 0 30px);
            position: relative;
            border-top: 2px solid transparent;

            @include breakpoint(laptop) {
                @include rem(font-size, 14px);
                @include rem(line-height, 80px);
            }

            &::before {
                content: '';
                @include abs-pos(50%, auto, auto, 0);
                @include rem(width, 20px);
                @include rem(height, 19px);
                @include transform(translateY(-50%));
            }

            &::after {
                content: '';
                @include abs-pos(50%, 0, auto, auto);
                @include rem(width, 11px);
                @include rem(height, 6px);
                @include transform(translateY(-50%) translateY(2px));
                background: url('../images/svg/navigation-arrow-white-down.svg') no-repeat center center;
            }

            @include modifier(date) {
                &::before {
                    background: url('../images/svg/filter-icon--date.svg') no-repeat center center;
                }
            }

            @include modifier(type) {
                &::before {
                    background: url('../images/svg/filter-icon--type.svg') no-repeat center center;
                }
            }
            @include modifier(status) {
                &::before {
                    background: url('../images/svg/filter-icon--status.svg') no-repeat center center;
                }
            }

            @include modifier(planned) {
                &::before {
                    background: url('../images/svg/filter-icon--planned.svg') no-repeat center center;
                }
            }
        }
    }

    @include element(button-small-text) {
        display: block;
        color: #777;
        @include rem(font-size, 13px);
        @include rem(line-height, 13px);
        margin: 0;
        padding: 0;
        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include element(checkboxes) {
        @include breakpoint(tablet) {
            @include rem(padding, 20px 20px 0);
        }
    }

    @include element(radios) {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    @include element(checkbox-holder) {
        display: block;
        position: relative;
        border-bottom: 1px solid #DDD;

        @include breakpoint(tablet) {
            border: none;
            width: 50%;
            float: left;
            &:nth-child(odd) {
                clear: both;
            }
        }

        @include modifier(active) {
            .fh {
                @include element(checkbox-label) {
                    font-weight: 700;
                    @include breakpoint(tablet) {
                        font-weight: 400;
                    }
                }
            }
        }

        @include modifier(ff) {
            width: auto;
            float: left;
            border: none;
            overflow: hidden;
            &:nth-child(odd) {
                clear: none;
            }
        }
    }

    @include element(checkbox-label) {
        display: table;
        @include rem(width, 100%);
        @include rem(padding, 0 50px 0 25px);
        @include rem(height, 60px);
        color: #555;
        font-weight: 400;
        vertical-align: middle;
        cursor: pointer;
        @include rem(font-size, 14px);
        span {
            display: table-cell;
            vertical-align: middle;
        }

        @include breakpoint(tablet) {
            color: #999;
            @include rem(font-size, 12px);
            @include rem(line-height, 16px);
            @include rem(padding, 0 10px 0 30px);
            @include rem(height, 16px);
            @include rem(margin, 0 0 20px);

            &::before {
                content: '';
                @include abs-pos(0, auto, auto, 0);
                @include rem(width, 14px);
                @include rem(height, 14px);
                border: 1px solid #DDD;
                @include border-radius(3px);
            }
        }

        @include modifier(ff) {
            border: none;
            margin-bottom: 0;
            color: #555;
            @include rem(font-size, 14px);
            @include rem(line-height, 16px);
            @include rem(padding, 0 10px 0 23px);
            @include rem(height, 16px);
            @include rem(margin, 0 10px 10px 0);
            @include breakpoint(tablet) {
                @include rem(margin, 0 10px 0px 0);
            }

            &::before {
                content: '';
                @include abs-pos(0, auto, auto, 0);
                @include rem(width, 14px);
                @include rem(height, 16px);
                background: #FFF;
                border: 1px solid #BBB;
                @include border-radius(3px);
                cursor: pointer;
            }
        }
    }

    @include element(checkbox) {
        @include abs-pos(50%, 20px, auto, auto);
        @include transform(translateY(-50%));
        @include rem(width, 18px);
        @include rem(height, 15px);
        border: 0;
        outline: none;
        background: none;
        padding: 0;
        -webkit-appearance: none;

        &.form__element--error {
            border: 0;
        }

        @include breakpoint(tablet) {
            @include abs-pos(0, auto, auto, 0);
            @include rem(width, 14px);
            @include rem(height, 14px);
            margin: 0;
            @include transform(translateY(0));
        }

        &:checked {
            background: url('../images/svg/filter-form__tick.svg') center center no-repeat;
            background-size: contain;
            @include breakpoint(tablet) {
                background-size: 70% 70%;
            }
        }

        @include modifier(ff) {
            @include abs-pos(0, auto, auto, 0);
            @include rem(width, 14px);
            @include rem(height, 14px);
            margin: 0;
            @include transform(translateY(0));

            &:checked {
                background-size: 70% 70%;
            }
        }
    }

    @include element(radio-holder) {
        display: block;
        position: relative;
        border-bottom: 1px solid #DDD;

        @include breakpoint(tablet) {
            border: none;
            display: inline-block;
            @include rem(min-width, 50px);
            position: relative;
            @include rem(margin, 0 4px 4px auto);
        }

        @include modifier(active) {
            .fh {
                @include element(checkbox-label) {
                    font-weight: 700;
                    @include breakpoint(tablet) {
                        font-weight: 400;
                    }
                }
            }
        }

        @include modifier(ff) {
            width: auto;
            float: left;
            border: none;
            overflow: hidden;
            &:nth-child(odd) {
                clear: none;
            }
        }
    }

    @include element(radio-label) {
        display: table;
        @include rem(width, 100%);
        @include rem(padding, 0 50px 0 25px);
        @include rem(height, 60px);
        color: #555;
        font-weight: 400;
        vertical-align: middle;
        cursor: pointer;
        @include rem(font-size, 14px);
        span {
            display: table-cell;
            vertical-align: middle;

            @include breakpoint(tablet) {
                text-align: center;
                width: 100%;
                display: inline-block;
            }
        }

        @include breakpoint(tablet) {
            display: inline-block;
            color: #999;
            @include rem(font-size, 12px);
            @include rem(line-height, 16px);
            @include rem(padding, 6px 11px 7px);
            height: auto;
            margin: 0;
            border: 1px solid #CCC;
            text-align: center;
            width: 100%;
            @include border-radius(3px);
        }

        @include modifier(ff) {
            border: none;
            margin-bottom: 0;
            color: #555;
            @include rem(font-size, 14px);
            @include rem(line-height, 16px);
            @include rem(padding, 0 10px 0 23px);
            @include rem(height, 16px);
            @include rem(margin, 0 10px 10px 0);
            @include breakpoint(tablet) {
                @include rem(margin, 0 10px 0px 0);
            }

            &::before {
                content: '';
                @include abs-pos(0, auto, auto, 0);
                @include rem(width, 14px);
                @include rem(height, 16px);
                background: #FFF;
                border: 1px solid #D8D8D8;
                @include border-radius(3px);
                cursor: pointer;
            }
        }
    }

    @include element(radio-icon) {
        @include rem(width, 24px);
        @include rem(height, 24px);
        border: 1px solid #C1C1C1;
        border-radius: 50%;
        @include abs-pos(50%, 20px, auto, auto);
        transform: translateY(-50%);
        pointer-events: none;

        @include breakpoint(tablet) {
            display: none;
        }

        &::after {
            content: '';
            @include abs-pos(50%, auto, auto, 50%);
            transform: translate(-50%, -50%);
            border-radius: 50%;
            @include rem(width, 16px);
            @include rem(height, 16px);

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    @include element(radio) {
        @include abs-pos(50%, 20px, auto, auto);
        @include transform(translateY(-50%));
        @include rem(width, 18px);
        @include rem(height, 15px);
        border: 0;
        outline: none;
        background: none;
        padding: 0;
        -webkit-appearance: none;
        opacity: 0;

        &.form__element--error {
            border: 0;
        }

        @include breakpoint(tablet) {
            @include abs-pos(0, auto, auto, 0);
            @include rem(width, 14px);
            @include rem(height, 14px);
            margin: 0;
            @include transform(translateY(0));
        }

        &:checked {
            + .fh__radio-icon:after {
                background: #00b2a1;
            }

            @include breakpoint(tablet) {
                background-size: 70% 70%;
                + .fh__radio-icon + .fh__radio-label {
                    color: #00b2a1;
                    border-color: #00b2a1;
                }
            }
        }

        @include modifier(ff) {
            @include abs-pos(0, auto, auto, 0);
            @include rem(width, 14px);
            @include rem(height, 14px);
            margin: 0;
            @include transform(translateY(0));

            &:checked {
                background-size: 70% 70%;
            }
        }
    }

    @include element(button-holder) {
        background: #EEE;
        border-top: 1px solid #DDD;
        @include abs-pos(auto, auto, 0, 0);
        position: fixed;
        @include rem(height, 78px);
        width: 100%;
        @include rem(max-width, 360px);
        @include rem(padding, 17px 20px 18px);
        @include flex;
        @include vendor-simple(flex-flow, row);
        @include vendor-simple(justify-content, stretch);
        @include breakpoint(tablet) {
            display: none !important;
        }

        @include modifier(hidden) {
            display: none;
        }
    }

    @include element(ab) {
        -webkit-appearance: none;
        cursor: pointer;
        border: none;
        @extend .helvetica-neue;
        font-weight: 700;
        @include rem(height, 40px);
        @include border-radius(5px);
        width: 50%;
        @include rem(margin-left, 15px);

        &:focus {
            outline: none;
        }

        @include modifier(cancel) {
            color: #777;
            margin: 0;
            background: #DDD;
        }

        @include modifier(apply) {
            color: #FFF;
            background: #00b2a1;
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include element(label) {
        @extend .helvetica-neue;
        text-transform: uppercase;
        font-weight: 700;
        color: #555;
        display: block;
        border-bottom: 1px solid rgb(221, 221, 221);
        @include rem(padding, 15px 20px);

        @include modifier(small) {
            text-transform: none;
            font-weight: 500;
            background: #F8F8F8;
        }
    }

    @include element(clear) {
        display: none;
        @include breakpoint(tablet) {
            display: block;
            text-decoration: none;
            @include abs-pos(20px, 25px, auto, auto);
            color: #4D4D4D;
            opacity: 0.3;
            @include rem(font-size, 12px);
            font-weight: 600;
            @include rem(line-height, 12px);
        }
    }

    @include element(desktop-date-input) {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: relative;
        }
    }

    @include element(date-popup) {
        @include breakpoint(tablet) {
            display: none;
            background: #FFF;
            @include vendor-simple(box-shadow, 0px 0px 5px 0px rgba(0, 0, 0, 0.4));
            z-index: 100;
            @include rem(width, 400px);
            @include abs-pos(calc(100% + 10px), 0, auto, auto);

            &::before {
                content: '';
                background: #FFF;
                z-index: 1;
                @include rem(width, 20px);
                @include rem(height, 20px);
                @include vendor-simple(box-shadow, 0px 0px 5px 0px rgba(0, 0, 0, 0.4));
                @include abs-pos(-5px, 28px, auto, auto);
                transform: rotate(45deg);
            }

            &::after {
                content: '';
                @include abs-pos(0, 0, auto, auto);
                z-index: 2;
                background: #FFF;
                @include rem(width, 100px);
                @include rem(height, 25px);
            }

            @include modifier(open) {
                display: block;
            }

            @include modifier(show-from) {
                @include rem(width, 700px);

                .fh__datepicker {
                    display: none;
                    @include modifier(from) {
                        display: block;
                    }
                }
            }

            @include modifier(show-to) {
                @include rem(width, 700px);

                .fh__datepicker {
                    display: block;
                    @include modifier(from) {
                        display: none;
                    }
                }
            }
        }
    }

    @include element(datepicker) {
        @include breakpoint(tablet) {
            display: none;
            @include abs-pos(32px, 394px, auto, auto);

            .datepicker {
                @include rem(width, 294px);
            }
        }
    }

    @include element(date-popup-container) {
        @include breakpoint(tablet) {
            @include rem(width, 400px);
            @include rem(min-height, 315px);
            float: right;
            @include rem(padding, 32px 27px 28px);
        }
    }

    @include element(date-popup-buttons) {
        display: none;

        @include breakpoint(tablet) {
            display: flex;
        }
    }

    @include element(date-buttons-half) {
        width: 50%;
        @include rem(margin-right, 10px);

        &:nth-child(even) {
            margin: 0;
        }
    }

    @include element(date-button) {
        @extend .helvetica-neue;
        @include rem(height, 38px);
        background: #DDD;
        border: none;
        -webkit-appearance: none;
        text-align: center;
        width: 100%;
        color: #555;
        @include rem(font-size, 13px);
        @include rem(line-height, 36px);
        @include border-radius(3px);
        @include rem(margin, 0 0 5px);
        cursor: pointer;

        &:focus {
            outline: none;
        }

        @include modifier(active) {
            color: #FFF;
            background: #00b2a1;
        }
    }

    @include element(date-save-buttons) {
        display: none;
        clear: both;

        @include breakpoint(tablet) {
            background: #EEE;
            @include rem(padding, 25px 26px);
            @include flex();
            @include vendor-simple(justify-content, flex-end);
            width: 100%;

            .fh__ab {
                @include vendor-simple(flex, 0 0 auto);
                display: block;
                @include rem(width, 168px);
                @include rem(height, 48px);
                text-transform: uppercase;
                margin: 0;
                @include rem(margin-right, 10px);
                background: #00b2a1;
                border: 1px solid #00b2a1;
                @include border-radius(3px);

                @include modifier(cancel) {
                    border-color: #AAA;
                    background: none;
                    color: #555;
                    margin: 0;
                }
            }
        }
    }

    @include element(date-popup-inputs) {
        @include breakpoint(tablet) {
            @include rem(padding, 14px 0 0);
            @include flex();

            .fh__label {
                @include rem(font-size, 14px);
                @include rem(line-height, 14px);
                @include rem(padding, 0 0 8px);
                border: none;
            }

            .input--filter {
                font-weight: 400;
                color: #999;
                @include rem(height, 38px);
                border: 1px solid #CCC;
                @include rem(font-size, 13px);
                @include rem(line-height, 38px);
                @include rem(padding, 0 10px);
                @include border-radius(3px);
                background-clip: padding-box;

                &::-webkit-inner-spin-button {
                    display: none;
                    -webkit-appearance: none;
                }

                &::-webkit-calendar-picker-indicator {
                    display: none;
                    -webkit-appearance: none;
                }
            }
        }
    }

    @include element(date-popup-inputs-half) {
        @include breakpoint(tablet) {
            @include vendor-simple(flex, 1 1 auto);
            @include rem(width, 170px);
            @include rem(margin, 0 15px 0 0);

            &:nth-child(even) {
                margin: 0;
            }
        }
    }

    @include element(date-input-icon-wrap) {
        @include breakpoint(tablet) {
            display: block;
            position: relative;

            &::before {
                content: '';
                @include abs-pos(50%, 10px, auto, auto);
                @include rem(width, 20px);
                @include rem(height, 18px);
                @include transform(translateY(-50%));
                background: url('../images/svg/filter-icon--date-blue.svg') no-repeat center center / contain;
                pointer-events: none;
                -ms-pointer-events: none;
            }
        }

        @include modifier(desktop) {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    @include element(slider-values) {
        @include flex();
        @include rem(padding, 24px 0 10px);

        @include breakpoint(tablet) {
            @include rem(padding, 14px 0 10px);
        }
    }

    @include element(slider-value) {
        width: 50%;
        text-align: left;
        color: #777;
        @extend .helvetica-neue;
        font-weight: 300;
        @include rem(font-size, 12px);
        @include rem(line-height, 12px);

        @include modifier(right) {
            text-align: right;
        }
    }

    @include element(mobile-view) {
        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include element(desktop-view) {
        display: none;
        @include breakpoint(tablet) {
            display: block;
        }
    }
}

input::-webkit-outer-spin-button, /* Removes arrows */
input::-webkit-inner-spin-button, /* Removes arrows */
input::-webkit-clear-button { /* Removes blue cross */
    -webkit-appearance: none;
    display: none;
    margin: 0;
}
