.label {
    display: block;
    color: #FFF;
    font-weight: 500;

    @include rem(font-size, 16px);
    @include rem(line-height, 18px);
    @include rem(padding, 0 0 2px);


    span {
        font-weight: 400;
        @include rem(font-size, 12px);
        vertical-align: baseline;
    }

}
