.btn {
    @extend .helvetica-neue !optional;
    display: block;
    position: relative;
    -webkit-appearance: none;
    border: none;
    outline: none;
    @include border-radius(5px);
    @include rem(height, 40px);
    color: #FFF;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    @include rem(font-size, 12px);
    @include rem(line-height, 12px);
    letter-spacing: 0.025rem;

    &::after {
        /**LoadingIcon**/
        content: '';
        @include abs-pos(50%, auto, auto, 50%);
        opacity: 0;
        background: url('../images/svg/loading.svg') no-repeat center center;
        background-size: contain;
        @include rem(width, 40px);
        @include rem(height, 40px);
        @include transform(translate(-50%, -50%));
        @include transition(opacity 300ms linear);
    }

    @include breakpoint(phablet) {
        @include rem(height, 50px);
        @include rem(padding, 0 45px 2px 40px);
    }

    @include breakpoint(1600) {
        @include rem(height, 60px);
        @include rem(line-height, 14px);
        @include rem(font-size, 14px);
    }

    @include modifier(blue) {
        background: #7CC400;
    }

    @include modifier(red) {
        background: #E00014;
    }

    @include modifier(grey) {
        background: #EEE;
        color: #999;
        &::after {
            background: url('../images/svg/loading-dark.svg') no-repeat center center;
            background-size: contain;
        }
    }

    @include modifier(dark-grey) {
        background: #666;
    }

    @include modifier(load-more) {
        font-weight: 400;
        width: 100%;
        @include rem(height, 60px);
        @include rem(font-size, 14px);
        @include rem(line-height, 60px);

        @include breakpoint(phablet) {
            @include rem(width, 170px);
            padding: 0;
            display: inline-block;
        }
    }

    @include modifier(float) {
        float: left;
        @include rem(margin, 0 10px 10px 0);

        @include breakpoint(tablet) {
            @include rem(margin, 0 10px 0 0);
        }
    }

    @include modifier(notification) {
        width: 220px;
        padding: 0;
        display: block;
        text-decoration: none;
    }

    @include modifier(filter) {
        background: none;
        outline: none;
        border: 0;
        @include rem(font-size, 11px);
        font-weight: 400;
        color: #00b2a1;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include rem(padding, 14px 10px 14px 29px);
        display: inline-block;
        float: right;
        width: auto;
        position: relative;
        cursor: pointer;
        @include rem(margin, 11px 0);

        @include breakpoint("laptop") {
            display: none;
        }

        &:focus {
            border: none;
        }

        &::before {
            content: "";
            background: url('../images/svg/btn__filter-icon.svg') center center no-repeat;
            background-size: contain;
            @include rem(width, 16px);
            @include rem(height, 16px);
            @include abs-pos(50%, auto, auto, 6px);
            @include transform(translateY(-50%));
        }
    }

    @include modifier(inline) {
        @include rem(width, 40px);
        @include border-radius(0 5px 5px 0);
        overflow: hidden;
        text-indent: -9999px;
        @include vendor-simple(flex, 0 0 auto);

        &::before {
            content: '';
            @include abs-pos(50%, auto, auto, 50%);
            @include transform(translate(-50%, -50%));
            @include rem(width, 6px);
            @include rem(height, 11px);
            background: url('../images/svg/navigation-arrow-white.svg') no-repeat center center;
            background-size: contain;
        }

        @include breakpoint(phablet) {
            text-indent: 0;
            width: auto;
            &::before {
                display: none;
            }
        }
    }

    @include modifier(dark) {
        background: #111;
        border: 2px solid #111;

        &:hover, &:focus {
            background: #FDFDFD;
            color: #111;
        }

        &.btn--loading {
            background: #111;
        }
    }

    @include modifier(submit) {
        @include rem(width, 220px);
        @include rem(height, 60px);
    }

    @include modifier(submit-alt) {
        @include rem(width, 220px);
        @include rem(padding, 0);
        @include rem(height, 60px);
        @include rem(margin, 0 15px 10px 0);
        @include breakpoint(laptop) {
            @include rem(margin, 0 15px 0 0);
        }
    }

    @include modifier(ui) {
        text-decoration: none;
        text-align: center;
        @include abs-pos(auto, 20px, 20px, 20px);
        @include rem(width, 150px);
        @include rem(height, 50px);
        @include rem(line-height, 50px);
        padding: 0;
        @include rem(font-size, 14px);

        @include breakpoint(laptop) {
            opacity: 0;
        }
    }

    @include modifier(loading) {
        color: transparent !important;
        &::after {
            opacity: 1;
        }

        @include ie11 {
            &::after {
                @include animation(0ms, 600ms, ie11-loading, ease-out, infinite);
            }
        }
    }

    @include modifier(login) {
        @include rem(width, auto);
        @include rem(padding, 0 18px);
        @include rem(height, 50px);
        display: inline-block;
        text-decoration: none;
        @include rem(line-height, 50px);
    }

    @include modifier(login-alt) {
        @include rem(width, 165px);
        @include rem(padding, 0 0);
        @include rem(height, 50px);
        display: inline-block;
        text-decoration: none;
        @include rem(line-height, 50px);
    }

    @include modifier(login-cancel) {
        @include rem(margin-left, 19px);
        @include rem(padding, 0 22px);
        @include rem(width, auto);
        @include rem(height, 50px);
        display: inline-block;
        text-decoration: none;
        @include rem(line-height, 50px);
    }

    @include modifier(account) {
        background: #FFF;
        color: #555;
        text-transform: uppercase;
        text-decoration: none;
        @include rem(height, 40px);
        @include rem(width, 105px);
        @include rem(font-size, 10px);
        @include rem(line-height, 39px);
        @include rem(padding, 1px 10px 0 40px);
        letter-spacing: 0;
        font-weight: 500;
        position: relative;

        svg {
            fill: #555;
        }
    }

    @include modifier(logout) {
        color: #B1E2FF;
        text-transform: uppercase;
        text-decoration: none;
        @include rem(height, 40px);
        @include rem(width, 105px);
        border: 1px solid #B1E2FF;
        @include rem(font-size, 10px);
        @include rem(line-height, 39px);
        @include rem(padding, 1px 10px 0 40px);
        letter-spacing: 0;
        font-weight: 500;
        position: relative;

        svg {
            fill: #B1E2FF;
        }
    }

    @include modifier(large) {
        @include rem(max-width, 400px);
        width: 100%;
        @include rem(font-size, 14px);
        @include rem(line-height, 14px);
        margin: 0 auto;
        text-decoration: none;
        text-align: center;
        height: auto;
        @include rem(padding, 22px 15px 20px);
        background: #7CC400;

        @include breakpoint(tablet) {
            @include rem(padding, 32px 20px 30px);
            @include rem(font-size, 18px);
            @include rem(line-height, 18px);
        }

        @include breakpoint(laptop) {
            &:hover, &:focus {
                background: darken(#009DDC, 20%);
            }
        }
    }

    @include modifier(logout-alt) {
        background: #EEE;
        color: #777;
        @include rem(height, 40px);
        @include rem(width, 90px);
        text-decoration: none;
        text-transform: none;
        @include rem(font-size, 10px);
        @include rem(line-height, 39px);
        @include rem(padding, 1px 10px 0 38px);
        letter-spacing: 0;
        font-weight: 500;
        @include abs-pos(50%, 15px, auto, auto);
        @include transform(translateY(-50%));
        svg {
            fill: #777;
            @include transition(all 0.3s linear);
        }

        &:hover, &:focus {
            background: #777;
            color: #EEE;

            svg {
                fill: #EEE;
            }
        }
    }

    @include element(icon) {
        @include abs-pos(0, auto, auto, 0);
        @include rem(width, 40px);
        @include rem(height, 40px);
        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
        }

        @include modifier(csv-download) {
            @include abs-pos(50%, auto, auto, 15px);
            @include rem(width, 23px);
            @include rem(height, 20px);
            @include transform(translateY(-50%));

            @include breakpoint(tablet) {
                @include rem(left, 40px);
            }

            svg {
                fill: #FFF;
            }
        }
    }

    @include modifier(submit-small) {
        @include rem(width, 180px);
        @include rem(height, 60px);
        @include rem(font-size, 14px);
        @include rem(padding, 0);
    }

    @include breakpoint(laptop) {
        @include transition(all 0.3s linear);
        @include modifier(blue) {
            &:hover, &:focus {
                background: darken(#7CC400, 10%);
            }
        }
        @include modifier(grey) {
            &:hover, &:focus {
                color: #FFF;
                background: #5F5F5F;
            }
        }
    }

    @include element(input) {
        opacity: 0;
        display: inline-block;
        width: 1px;
        height: 1px;
    }

    @include modifier(icon) {
        height: auto;
        @include rem(padding, 17px 25px 17px 55px);
        text-align: left;

        @include breakpoint(tablet) {
            @include rem(font-size, 14px);
            @include rem(line-height, 14px);
            @include rem(padding, 30px 60px 30px 80px);
        }
    }
}

.btn--file {
    display: block !important;
    line-height: 60px !important;
}

.latest-btn-remit {
    @include rem(padding, 30px 20px 5px);
}

@include keyframes(ie11-loading) {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.1);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
