.form-row {
    width: 100%;
    @include modifier(inline) {
        position: relative;
        @include flex;
        @include vendor-simple(flex-flow, row);
    }
    @include modifier(subscriber) {
        @include rem(max-width, 500px);
        margin: 0 auto;
        @include breakpoint(1200) {
            @include rem(max-width, 510px);
        }
    }
}
