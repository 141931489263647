.form {
    @include element(row) {
        @include rem(padding, 0 0 35px);

        @include modifier(submit) {
            @include rem(padding, 0 0 20px);
        }

        @include modifier(login) {
            @include rem(padding, 0);
            text-align: left;
        }
    }

    @include element(wrap) {
        display: block;
        position: relative;
    }

    @include element(wrap-icon) {
        @include abs-pos(0, auto, auto, 0);
        @include rem(width, 50px);
        @include rem(height, 50px);
        svg {
            @include abs-pos(0, auto, auto, 0);
            width: 100%;
            height: 100%;
            fill: #00b2a1;
        }
    }
}
