.message {
    @include border-radius(10px);
    @include rem(margin, 20px);
    @include rem(padding, 10px 15px);
    text-align: center;

    p {
        margin: 0;
        padding: 0;
    }

    @include breakpoint("tablet") {
        @include rem(margin, 20px);
    }

    @include breakpoint("laptop") {
        @include rem(margin, 20px);
    }

    @include modifier("error") {
        background: #A94442;
        border: 1px solid #A94442;

        p {
            color: #FFF;

            a {
                color: #FFF;
                border-color: #FFF;
            }
        }
    }

    @include modifier("warning") {
        background: #FF7B12;
        border: 1px solid #FF7B12;

        p {
            color: #FFF;

            a {
                color: #FFF;
                border-color: #FFF;
            }
        }
    }

    @include modifier("info") {
        background: #90CCEE;
        border: 1px solid #90CCEE;

        p {
            color: #405B6A;

            a {
                color: #405B6A;
                border-color: #405B6A;
            }
        }
    }

    @include modifier("success") {
        background: #529E5D;
        border: 1px solid #529E5D;

        p {
            color: #FFF;

            a {
                color: #FFF;
                border-color: #FFF;
            }
        }
    }
}

.error-login .message {
    @include rem(margin, 0 0 20px);
    width: 100%;

    p {
        @include rem(font-size, 12px);
    }
}
