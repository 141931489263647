.headline-notification {
    display: block;
    text-align: center;
    @include rem(margin, 30px 20px 0);
    @include rem(padding, 14px 20px);
    background: rgba(0, 157, 220, 0.18);
    @include border-radius(5px);
    color: #005A7D;
    border: 1px solid #A8D9EC;
    @include rem(font-size, 14px);
    @include rem(line-height, 18px);

    @include breakpoint(tablet) {
        @include rem(margin, 30px 0 0);
    }

    a {
        color: #005A7D;
    }
}
