.tn {
    width: 100%;
    border-collapse: separate;
    @include max-breakpoint(759) {
        display: block;
        @include rem(padding, 20px 20px 0);

        tbody {
            display: block;
            width: 100%;
        }
    }

    @include breakpoint(tablet) {
        @include rem(margin, 26px 30px 30px);
        width: calc(100% - 60px);
        border: 2px solid #EEE;
    }

    @include breakpoint(1600) {
        @include rem(margin, 26px 40px 30px);
        width: calc(100% - 80px);
    }

    tr td .notification {
        width: 100%;
        text-align: center;
        @include rem(padding, 27px 30px 29px);
    }

    @include element(tr) {
        @include max-breakpoint(759) {
            border: 2px solid #EEE;
            width: 100%;
            display: block;
            @include rem(margin, 0 0 20px);

            @include modifier(th) {
                display: none;
            }
        }

        border-top: 2px solid #EEE;

        @include modifier(full-view) {
            //display: none;
            background: #F8F8F8;
            @include rem(padding, 8px 8px 0);

            @include max-breakpoint(759) {
                @include abs-pos(0, auto, auto, 0);
                position: fixed;
                width: 100%;
                height: 100%;
                overflow: auto;
                z-index: 1000;
            }
        }
    }

    @include element(full-view) {
        @include max-breakpoint(759) {
            display: block;
        }
    }

    @include element(th) {
        @include max-breakpoint(759) {
            display: none;
        }
        @extend .helvetica-neue;
        font-weight: 500;
        @include rem(padding, 12px 10px);
        background: #EEE;
        color: #555;
        border-left: 2px solid #FFF;
        border-bottom: 2px solid #FFF;
        @include rem(font-size, 13px);
        @include rem(line-height, 17px);
        vertical-align: middle;

        @include breakpoint(1200) {
            @include rem(font-size, 11px);
            @include rem(line-height, 15px);
        }

        @include breakpoint(1600) {
            border-bottom: 2px solid #FFF;
            @include rem(font-size, 14px);
            @include rem(line-height, 17px);
            @include rem(padding, 13px 15px);
        }

        &:nth-child(1) {
            border-left: none;
        }
    }

    @include element(td) {
        @extend .helvetica-neue;
        font-weight: 400;
        @include rem(font-size, 12px);
        @include rem(line-height, 18px);
        color: #555;

        @include breakpoint(phablet) {
            @include rem(font-size, 14px);
            @include rem(line-height, 20px);
        }

        @include breakpoint(tablet) {
            text-align: center;
            @include rem(font-size, 13px);
            @include rem(line-height, 17px);
            @include rem(padding, 12px 10px);
            border-left: 2px solid #EEE;
            border-top: 2px solid #EEE;
            vertical-align: middle;

            &:nth-child(1) {
                border-left: none;
            }
        }

        @include breakpoint(1200) {
            @include rem(font-size, 11px);
            @include rem(line-height, 15px);

            @include modifier('event-id') {
                @include rem(width, 108px);
            }
        }

        @include breakpoint(1600) {
            @include rem(font-size, 14px);
            @include rem(line-height, 17px);
            @include rem(padding, 13px 15px);

            @include modifier('event-id') {
                @include rem(width, 138px);
            }
        }

        @include max-breakpoint(759) {
            display: block;
            width: 100%;
            text-align: left;
            border-top: 2px solid #EEE;
            @include rem(padding, 7px 15px 7px 150px);
            position: relative;

            @include breakpoint(phablet) {
                @include rem(padding, 10px 20px 10px 190px);
            }

            &::before {
                content: attr(data-th);
                display: block;
                font-weight: 500;
                @include abs-pos(7px, auto, auto, 15px);

                @include breakpoint(phablet) {
                    @include abs-pos(10px, auto, auto, 20px);
                }
            }

            @include modifier('event-id') {
                background: #EEE;
                text-align: center;
                border: none;
                font-weight: 500;
                @include rem(padding, 7px 15px);

                @include breakpoint(phablet) {
                    @include rem(padding, 10px 20px);
                }

                &::before {
                    display: block;
                    text-align: center;
                    @include relative-reset;
                }
            }

            @include modifier(button) {
                &::before {
                    display: none;
                }

                @include rem(padding, 8px);

                @include breakpoint(phablet) {
                    @include rem(padding, 14px);
                }

            }

        }
    }

    @include element(more-info) {
        @extend .helvetica-neue;
        font-weight: 700;
        border: none;
        -webkit-appearance: none;
        width: 100%;
        @include border-radius(3px);
        background: #00b2a1;
        color: #FFF;
        text-transform: uppercase;
        @include rem(font-size, 13px);
        @include rem(line-height, 19px);
        text-align: center;
        @include rem(padding, 16px 15px 15px);
        cursor: pointer;

        @include breakpoint(phablet) {
            @include rem(font-size, 15px);
            @include rem(line-height, 22px);
        }

        @include breakpoint(tablet) {
            background: none;
            @include rem(height, 22px);
            @include rem(width, 22px);
            @include border-radius(0);
            padding: 0;
            text-indent: -9999px;
            position: relative;

            &::before, &::after {
                content: '';
                @include abs-pos(50%, auto, auto, 50%);
                @include transform(translate(-50%, -50%) translateZ(0px));
                @include rem(width, 2px);
                @include rem(height, 22px);
                background: #00b2a1;
            }

            &::after {
                @include rem(width, 22px);
                @include rem(height, 2px);
            }
        }

        &:focus {
            outline: none;
        }

        @include modifier("open") {
            &::before {
                display: none;
            }
        }
    }

    @include element('ellipse') {
        display: none;
        @include breakpoint(tablet) {
            display: inline;
        }
    }

    @include element(hidden-text) {
        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include element(tooltip-link) {
        display: none;
        color: #009DDC;
        cursor: pointer;

        b {
            color: #555;
        }

        @include breakpoint(tablet) {
            display: inline;
        }
    }
}

.tabular-notifications-wrap {
    position: relative;

    &::before {
        content: '';
        pointer-events: none;
        -ms-pointer-events: none;
        opacity: 0;
        @include abs-pos(0, 0, 0, 0);
        z-index: 10;
        background: #FFF;
    }

    &::after {
        content: '';
        @include abs-pos(125px, auto, auto, 50%);
        opacity: 0;
        background: url('../images/svg/loading-dark.svg') no-repeat center center;
        background-size: contain;
        @include rem(width, 40px);
        @include rem(height, 40px);
        @include transform(translate(-50%, -50%));
        pointer-events: none;
        -ms-pointer-events: none;
        z-index: 10;
    }

    @include modifier(loading) {
        @include rem(min-height, 50px);
        &::before {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 0.7;
        }
        &::after {
            pointer-events: all;
            -ms-pointer-events: all;
            opacity: 1;
        }
    }
}
