/*Required Variables*/
$base-font-size: 16px;

/*Z-index Scale*/
$z-index-minus: -100;
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;

$primary_blue: #00b2a1;
$primary_white: #FFF;
$primary_grey: #555;

//$primary_font: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$primary_font: "Arial", sans-serif;
